import React, {useCallback, useState} from "react";
import {useStore} from "../../store/useStore";

// Assets
import logo from "../../assets/img/logo_bmxx.png";

// font Awesome
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faSearch, faUserCircle} from "@fortawesome/free-solid-svg-icons";

// Bootstrap
import Button from "react-bootstrap/cjs/Button";
import Image from "react-bootstrap/cjs/Image";

// Material-UI
import AppBar from "@material-ui/core/AppBar";
import {Toolbar} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// i18n - react intl
import {useIntl} from "react-intl";

// Components
import Navigation from "../navigation/Navigation";
import SelectProject from "../selectProject/SelectProject";

// Helper
import pages from "../../config/pages";
import axios from "axios";
import config from "../../config/config";
import {GlobalTransIntl} from "../../helper/GlobalTrans";

function Header(props) {
    const intl = useIntl();
    const {state, dispatch} = useStore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const login = props.login || false;

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const [cancelToken] = useState(axios.CancelToken.source());

    const logout = useCallback(
        () => {
            axios.get(config.apiUrl + `/logout`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(() => {
                    dispatch({type: "deleteTokens"});
                    dispatch({type: "resetApp"});
                })
                .catch(() => {
                    console.log("error logout");
                });
        },
        [dispatch, cancelToken.token, state.token]
    );

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMyAccount = useCallback(() => {
        dispatch({type: "setPage", payload: pages.ACCOUNT_CHANGE_PASSWORD});
    }, [dispatch]);

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMyAccount}>My account</MenuItem>
            {
                state.token &&
                <MenuItem onClick={logout}>Logout</MenuItem>
            }
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <p>Profile</p>
            </MenuItem>
            {
                state.token &&
                <MenuItem onClick={logout}>Logout</MenuItem>
            }
        </Menu>
    );

    const onSearch = (e) => {
        e.preventDefault();

        dispatch({
            type: 'setEditForm',
            payload: {search}
        });

        dispatch({
            type: 'setPage',
            payload: pages.GLOBAL_PIN_SEARCH
        });

        setSearch('');
    }

    return (
        <div className="grow">
            <AppBar className={'header'}>
                <Toolbar>
                    {
                        !login &&
                        <React.Fragment>
                            <div className="header--logo">
                                <Button variant="link">
                                    <Image src={logo || ''} alt={'Logo'} fluid/>
                                </Button>
                            </div>
                            <div className='header--search'>
                                <form onSubmit={onSearch}>
                                    <div className='header--search-icon'>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </div>
                                    <InputBase
                                        placeholder={GlobalTransIntl('header_search_placeholder', intl)}
                                        classes={{
                                            root: 'header--search-input--root',
                                            input: 'header--search-input--input',
                                        }}
                                        inputProps={{'aria-label': 'search'}}
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                    />
                                </form>
                            </div>
                        </React.Fragment>
                    }

                    <div className="grow"/>
                    <Navigation/>
                    {
                        !login &&
                        <React.Fragment>
                            <span className='header--project-label'>
                                {GlobalTransIntl('project', intl)}:
                            </span>
                            <SelectProject id={'header'} size={20}/>
                            <div className='header--desktop'>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={handleProfileMenuOpen}
                                >
                                    <FontAwesomeIcon icon={faUserCircle}/>
                                </IconButton>
                            </div>
                            <div className='header--mobile'>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faEllipsisV}/>
                                </IconButton>
                            </div>
                        </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            {
                !login &&
                (
                    <React.Fragment>
                        {renderMobileMenu}
                        {renderMenu}
                    </React.Fragment>
                )
            }
        </div>
    )
}

export default Header;