import React, {useCallback, useEffect, useState, Fragment} from "react";
import Spinner from "../spinner/Spinner";
import {Box, CardHeader, Grid, Paper} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {useIntl} from "react-intl";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import {getFormatedTime} from "../../helper/Helper";
import {GlobalTransIntl} from "../../helper/GlobalTrans";

function ResultDetails() {
    const {state} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);

    const [loadingPin, setLoadingPin] = useState(true);
    const [loadingPinResult, setLoadingPinResult] = useState(true);
    const [pin, setPin] = useState(true);
    const [pinResult, setPinResult] = useState('');

    const getPin = useCallback(() => {
        axios.get(config.apiUrl + `/pins/${state.resultDetails}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then((res) => {
                if (res.data) {
                    setPin(res.data);
                    setLoadingPin(false);
                }
            })
            .catch();
    }, [state.resultDetails, state.token, cancelToken.token]);

    const getPinRawValues = useCallback(() => {
        axios.get(config.apiUrl + `/pin/raws/${state.resultDetails}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then((res) => {
                if (res.data?.dimensions) {
                    setPinResult(res.data.dimensions);
                    setLoadingPinResult(false);
                }
            })
            .catch();

    }, [cancelToken.token, state.resultDetails, state.token]);

    useEffect(() => {
        if (!mounted && state.resultDetails) {
            setMounted(true);
            getPin();
            getPinRawValues();
        }
    }, [mounted, state.resultDetails, getPin, getPinRawValues]);

    const getFullName = () => {
        return pin.user.firstName + ' ' + pin.user.lastName;
    }

    const rawValue = (resultKey) => {
        if (pinResult[resultKey]?.fc && pinResult[resultKey]?.questionaire) {
            return <span> (FC {pinResult[resultKey].fc} | questioniare {pinResult[resultKey].questionaire})</span>;
        }

        return null;
    };

    const getPoints = (value) => {
        if (value?.answeredAt) {
            return `${value.points} (${(value.answeredAt)})`;
        }

        return value.points;
    }

    return (
        <Paper className={'result-details'}>
            <Spinner show={loadingPin || loadingPinResult} rowClass={'p-5'}/>
            {
                (!loadingPin && !loadingPinResult) &&
                <Fragment>
                    <CardHeader title={GlobalTransIntl('details_for', intl) + ' ' + getFullName()}/>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('assessment', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    pin.procedureObject.name
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('created_at', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    getFormatedTime(pin.createdAt)
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('last_modified_at', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    getFormatedTime(pin.changedAt)
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('pin', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    pin.token
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('normgroup', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    pin.normgroup.name || '-'
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('started_at', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    getFormatedTime(pin.startedAt)
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('finished_at', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    getFormatedTime(pin.finishedAt)
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col'}>
                                {
                                    GlobalTransIntl('send_invite', intl)
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className={'result-details--col result-details--value'}>
                                {
                                    getFormatedTime(pin.invitedAt)
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Box p={2}>
                        <Grid container spacing={2}>
                            {
                                Object.keys(pinResult).map((resultKey, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12} md={6}>{pinResult[resultKey].dimension}</Grid>
                                        <Grid item xs={12} md={6}>
                                            {pinResult[resultKey].raw}
                                            {rawValue(resultKey)}
                                        </Grid>
                                        {
                                            pinResult[resultKey]?.tasks &&
                                            Object.keys(pinResult[resultKey].tasks).map((task, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={12}
                                                          md={6}>{pinResult[resultKey].tasks[task].label}</Grid>
                                                    <Grid item xs={12} md={6}>
                                                        {getPoints(pinResult[resultKey].tasks[task])}
                                                    </Grid>
                                                </React.Fragment>
                                            ))
                                        }
                                        <Grid item xs={12}>
                                            <hr/>
                                        </Grid>
                                    </React.Fragment>
                                ))
                            }
                        </Grid>
                    </Box>
                </Fragment>
            }
        </Paper>
    );
}

export default ResultDetails;