import React from "react";
import {useStore} from "../../store/useStore";
import config from "../../config/config";
import ThreeSixtyPins from "./results/ThreeSixtyPins";
import NormalPins from "./results/NormalPins";

function PinsResults() {
    const {state} = useStore();

    if (state.pinResult === config.pinResults.threeSixty) {
        return <ThreeSixtyPins/>;
    }

    return <NormalPins/>;
}

export default PinsResults;