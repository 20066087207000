import React, {createRef, useCallback, useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/cjs/Form";
import Button from "react-bootstrap/cjs/Button";
import {FormattedMessage} from "react-intl.macro";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Divider, Grid, Typography} from "@material-ui/core";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {
    checkMultipleAxios, deepCopy,
    getIRIFromObject,
    helperCatchErrors,
    inputOnlyInteger, scrollToTop,
    selectIconComponent
} from "../../helper/Helper";
import Validation from "../../helper/Validation";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import Notifications from "../notifications/Notifications";
import Spinner from "../spinner/Spinner";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TranslationsInput from "../translations/TranslationsInput";
import ImageUpload from "../imageUpload/ImageUpload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function ProjectForm() {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const threeSixtyCoverSheetLogoRef = useRef();
    const invitationAttachmentRef = useRef();

    const [providedBy, setProvidedBy] = useState(state.editForm.providedBy || '');
    const [name, setName] = useState(state.editForm.name || '');
    const [sloganTop, setSloganTop] = useState(state.editForm.sloganTop || '');
    const [sloganBottom, setSloganBottom] = useState(state.editForm.sloganBottom || '');
    const [reminderOne, setReminderOne] = useState(state.editForm.reminderOne || '');
    const [reminderTwo, setReminderTwo] = useState(state.editForm.reminderTwo || '');
    const [excludeAfter, setExcludeAfter] = useState(state.editForm.excludeAfter || '');
    const [reminderMailSubject, setReminderMailSubject] = useState(state.editForm.reminderMailSubject || '');
    const [reminderMailContent, setReminderMailContent] = useState(state.editForm.reminderMailContent || '');
    const [reminderMailFocuspersonSubject, setReminderMailFocuspersonSubject] = useState(state.editForm.reminderMailFocuspersonSubject || '');
    const [reminderMailFocuspersonContent, setReminderMailFocuspersonContent] = useState(state.editForm.reminderMailFocuspersonContent || '');
    const [reminderMailReferenceGiverSubject, setReminderMailReferenceGiverSubject] = useState(state.editForm.reminderMailReferenceGiverSubject || '');
    const [reminderMailReferenceGiverContent, setReminderMailReferenceGiverContent] = useState(state.editForm.reminderMailReferenceGiverContent || '');
    const [receiver, setReceiver] = useState(state.editForm.receiver || '');
    const [validReceiver, setValidReceiver] = useState(true);
    const [dataProtection, setDataProtection] = useState(state.editForm.dataProtection || '');
    const [country, setCountry] = useState(getIRIFromObject(state.editForm.country));
    const [customer, setCustomer] = useState(getIRIFromObject(state.editForm.customer));
    const [status, setStatus] = useState(getIRIFromObject(state.editForm.status));
    const [language, setLanguage] = useState(state.editForm.receiverLanguage || '');
    const [threeSixtySubjectFocusperson, setThreeSixtySubjectFocusperson] = useState(state.editForm.threeSixtySubjectFocusperson || '');
    const [threeSixtySubjectReferenceGiver, setThreeSixtySubjectReferenceGiver] = useState(state.editForm.threeSixtySubjectReferenceGiver || '');
    const [threeSixtyInviteFocusperson, setThreeSixtyInviteFocusperson] = useState(state.editForm.threeSixtyInviteFocusperson || '');
    const [threeSixtyReferenceGiver, setThreeSixtyReferenceGiver] = useState(state.editForm.threeSixtyReferenceGiver || '');
    const [invitationSubject, setInvitationSubject] = useState(state.editForm.invitationSubject || '');
    const [invitation, setInvitation] = useState(state.editForm.invitation || '');
    const [resultMailSubject, setResultMailSubject] = useState(state.editForm.resultMailSubject || '');
    const [resultMailContent, setResultMailContent] = useState(state.editForm.resultMailContent || '');
    const [threeSixtyIntroduction, setThreeSixtyIntroduction] = useState(state.editForm.threeSixtyIntroduction || '');
    const [threeSixtyDisclaimer, setThreeSixtyDisclaimer] = useState(state.editForm.threeSixtyDisclaimer || '');
    const [threeSixtyCoverSheetLogoOriginal] = useState(state.editForm.threeSixtyCoverSheetLogo || '');
    const [threeSixtyCoverSheetLogo, setThreeSixtyCoverSheetLogo] = useState('');
    const [threeSixtyCoverSheetLogoUpload, setThreeSixtyCoverSheetLogoUpload] = useState(false);
    const [expiredPinDays, setExpiredPinDays] = useState(state.editForm.expiredPinDays || '');
    const [checkboxDeactivateDataProtection, setCheckboxDeactivateDataProtection] = useState(state.editForm.deactivateDataProtection || false);
    const [invitationAttachmentOriginal] = useState(state.editForm.invitationAttachment || '');
    const [invitationAttachment, setInvitationAttachment] = useState('');
    const [invitationAttachmentUpload, setInvitationAttachmentUpload] = useState(false);
    const [invitationAttachments, setInvitationAttachments] = useState([]);
    const [originInvitationAttachments, setOriginInvitationAttachments] = useState([]);

    const [countries, setCountries] = useState(false);
    const [customers, setCustomers] = useState(false);
    const [languages, setLanguages] = useState(false);
    const [statuses, setStatuses] = useState(false);

    const [loadingData, setLoadingData] = useState(true);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingCustomers, setLoadingCustomers] = useState(true);
    const [loadingLanguages, setLoadingLanguages] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState(true);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [originalTranslations] = useState(state.editForm.translations || []);

    const getTranslationsWithAttachmentIRI = () => {
        const translations = [];

        if (state.editForm?.translations && state.editForm.translations.length) {
            state.editForm.translations.forEach(translation => {
                const newTranslation = {...translation};
                if (newTranslation?.attachment?.['@id']) {
                    newTranslation.attachment = newTranslation.attachment['@id'];
                }

                translations.push(newTranslation);
            });
        }

        return translations;
    }

    const [translations, setTranslations] = useState(getTranslationsWithAttachmentIRI());

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const getCustomers = useCallback(() => {
        axios.get(config.apiUrl + `/customers`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setCustomers(res.data['hydra:member']);
                    setLoadingCustomers(false);
                }
            })
            .catch(catchErrors);
    }, [state.token, catchErrors, cancelToken.token]);

    const getCountries = useCallback(() => {
        axios.get(config.apiUrl + `/countries`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setCountries(res.data['hydra:member']);
                    setLoadingCountries(false);
                }
            })
            .catch(catchErrors);
    }, [state.token, catchErrors, cancelToken.token]);

    const getLanguages = useCallback(() => {
        axios.get(config.apiUrl + `/languages`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setLanguages(res.data['hydra:member']);
                    setLoadingLanguages(false);
                }
            })
            .catch(catchErrors);
    }, [state.token, catchErrors, cancelToken.token]);

    const getStatus = useCallback(() => {
        axios.get(config.apiUrl + `/project_statuses`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setStatuses(res.data['hydra:member']);
                    setLoadingStatus(false);
                }
            })
            .catch(catchErrors);
    }, [state.token, catchErrors, cancelToken.token]);

    const findOriginalTranslationAttachment = useCallback((languageId, iri = true) => {
        const translation = originalTranslations.find((translation) => translation.language === languageId);

        if (iri && translation?.attachment?.['@id']) {
            return translation?.attachment?.['@id'];
        }

        return translation?.attachment;
    }, [originalTranslations]);

    useEffect(() => {
        let checkAllAxios = checkMultipleAxios(
            [
                loadingCountries,
                loadingCustomers,
                loadingLanguages,
                loadingStatus
            ],
            [
                {
                    name: 'country',
                    value: countries
                },
                {
                    name: 'customer',
                    value: customers
                },
                {
                    name: 'language',
                    value: languages
                },
                {
                    name: 'status',
                    value: statuses
                }
            ],
            intl
        )

        if (checkAllAxios.length) {
            showError(checkAllAxios);
            setLoadingData(false);
        } else if (checkAllAxios === true) {
            const newInvitationAttachments = [];

            languages.forEach((language) => {
                newInvitationAttachments.push({
                    language: language['@id'],
                    ref: createRef(),
                    upload: false,
                    original: findOriginalTranslationAttachment(language['@id']),
                })
            });

            setInvitationAttachments(newInvitationAttachments);
            setOriginInvitationAttachments(newInvitationAttachments);
            setLoadingData(false);
        }
    }, [loadingCountries, loadingCustomers, loadingLanguages, loadingStatus, countries, customers, languages, statuses, intl, findOriginalTranslationAttachment]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getCountries();
            getCustomers();
            getLanguages();
            getStatus();

            setMounted(true);
        }
    }, [mounted, getCountries, getCustomers, getLanguages, getStatus, editForm, dispatch]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken]);

    const validateReceiver = () => {
        if (receiver.length) {
            let isValidEmail = Validation.isEmail(receiver);

            setValidReceiver(isValidEmail);

            if (!language.length) {
                showError(
                    GlobalTransIntl(
                        'error_placeholder_missing',
                        intl,
                        {
                            placeholder: GlobalTransIntl('language', intl)
                        }
                    )
                );
                return false;
            }

            return isValidEmail;
        }

        return true;
    }

    const findTranslation = useCallback((language, translations) => {
        return translations.find((translation => translation.language === language));
    }, []);

    const setInvitationAttachmentsToTranslations = useCallback(() => {
        const newTranslations = [...translations];

        invitationAttachments.forEach(attachment => {
            if (attachment.file) {
                const translation = findTranslation(attachment.language, newTranslations);

                if (translation) {
                    translation['attachment'] = attachment.file;
                }
            }
        });

        setTranslations(newTranslations);
    }, [invitationAttachments, findTranslation, translations]);

    const sendForm = useCallback(() => {
        let data = {
            name,
            customer,
            country,
            status,
            sloganTop,
            sloganBottom,
            providedBy,
            receiver,
            translations,
            reminderOne: Number(reminderOne) || null,
            reminderTwo: Number(reminderTwo) || null,
            excludeAfter: Number(excludeAfter) || null,
            receiverLanguage: language || null,
            threeSixtyInviteFocusperson,
            threeSixtyReferenceGiver,
            threeSixtySubjectFocusperson,
            threeSixtySubjectReferenceGiver,
            threeSixtyIntroduction,
            threeSixtyDisclaimer,
            dataProtection,
            expiredPinDays: Number(expiredPinDays) || null,
            deactivateDataProtection: checkboxDeactivateDataProtection,
        };

        if (reminderMailSubject && reminderMailContent) {
            data.reminderMailSubject = reminderMailSubject;
            data.reminderMailContent = reminderMailContent;
        } else {
            data.reminderMailSubject = '';
            data.reminderMailContent = '';
        }

        if (reminderMailFocuspersonSubject && reminderMailFocuspersonContent) {
            data.reminderMailFocuspersonSubject = reminderMailFocuspersonSubject;
            data.reminderMailFocuspersonContent = reminderMailFocuspersonContent;
        } else {
            data.reminderMailFocuspersonSubject = '';
            data.reminderMailFocuspersonContent = '';
        }

        if (reminderMailReferenceGiverSubject && reminderMailReferenceGiverContent) {
            data.reminderMailReferenceGiverSubject = reminderMailReferenceGiverSubject;
            data.reminderMailReferenceGiverContent = reminderMailReferenceGiverContent;
        } else {
            data.reminderMailReferenceGiverSubject = '';
            data.reminderMailReferenceGiverContent = '';
        }

        if (resultMailSubject && resultMailContent) {
            data.resultMailSubject = resultMailSubject;
            data.resultMailContent = resultMailContent;
        } else {
            data.resultMailSubject = '';
            data.resultMailContent = '';
        }

        if (invitationSubject && invitation) {
            data.invitationSubject = invitationSubject;
            data.invitation = invitation;
        } else {
            data.invitationSubject = '';
            data.invitation = '';
        }

        if (threeSixtyCoverSheetLogo) {
            data.threeSixtyCoverSheetLogo = threeSixtyCoverSheetLogo;
        }

        if (invitationAttachment) {
            data.invitationAttachment = invitationAttachment;
        }

        if (editForm) {
            axios.put(config.apiUrl + `/projects/${editForm.id}`, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'Content-Type': 'application/ld+json'}
            }))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/projects`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data) {
                        setName('');
                        setCustomer('');
                        setCountry('');
                        setStatus('');
                        setSloganTop('');
                        setSloganBottom('');
                        setReminderOne('');
                        setReminderTwo('');
                        setExcludeAfter('');
                        setProvidedBy('');
                        setReceiver('');
                        setLanguage('');
                        setThreeSixtySubjectReferenceGiver('');
                        setThreeSixtySubjectFocusperson('');
                        setThreeSixtyInviteFocusperson('');
                        setThreeSixtyReferenceGiver('');
                        setInvitationSubject('');
                        setInvitation('');
                        setThreeSixtyCoverSheetLogo('');
                        setDataProtection('');
                        setExpiredPinDays('');
                        setCheckboxDeactivateDataProtection(false);
                        setInvitationAttachment('');
                        setInvitationAttachments(originInvitationAttachments);

                        threeSixtyCoverSheetLogoRef.current.reset();
                        invitationAttachmentRef.current.reset();
                        invitationAttachments.forEach((attachment) => {
                            if (attachment.ref.current) {
                                attachment.ref.current.reset();
                            }
                        });

                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }, [cancelToken.token, catchErrors, country, customer, editForm, excludeAfter, invitation, invitationSubject, language, name, providedBy, receiver, reminderMailContent, reminderMailSubject, reminderOne, reminderTwo, sloganBottom, sloganTop, state.token, status, threeSixtyCoverSheetLogo, threeSixtyDisclaimer, threeSixtyIntroduction, threeSixtyInviteFocusperson, threeSixtyReferenceGiver, threeSixtySubjectFocusperson, threeSixtySubjectReferenceGiver, translations, dataProtection, resultMailSubject, resultMailContent, expiredPinDays, checkboxDeactivateDataProtection, invitationAttachment, invitationAttachments, originInvitationAttachments, reminderMailFocuspersonContent, reminderMailFocuspersonSubject, reminderMailReferenceGiverContent, reminderMailReferenceGiverSubject]);

    const isEveryAttachmentUploaded = useCallback(() => {
        return invitationAttachments.findIndex((attachment) => attachment.upload === false) === -1;
    }, [invitationAttachments]);

    useEffect(() => {
        if (threeSixtyCoverSheetLogoUpload && invitationAttachmentUpload && !invitationAttachments.find((attachment) => attachment.upload === false)) {
            setThreeSixtyCoverSheetLogoUpload(false);
            setInvitationAttachmentUpload(false);
            setInvitationAttachmentsToTranslations();
            sendForm();
        }
    }, [threeSixtyCoverSheetLogoUpload, invitationAttachmentUpload, sendForm, invitationAttachments, setInvitationAttachmentsToTranslations, isEveryAttachmentUploaded]);

    const onSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        if (validateReceiver()) {
            threeSixtyCoverSheetLogoRef.current.sendFile();
            invitationAttachmentRef.current.sendFile();

            invitationAttachments.forEach((attachment) => {
                attachment.ref.current.sendFile();
            });
        }
    }

    const updateInvitationAttachments = (index, key, value) => {
        setInvitationAttachments(prev => {
            const newPrev = deepCopy(prev);

            newPrev[index][key] = value;

            return newPrev;
        });
    }

    const renderInvitationAttachmentUploads = useCallback(() => {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Box display={!translationLanguage ? 'block' : 'none'}>
                        <ImageUpload
                            ref={invitationAttachmentRef}
                            setImageIRI={setInvitationAttachment}
                            showError={showError}
                            catchErrors={catchErrors}
                            setUploadStatus={setInvitationAttachmentUpload}
                            originalIRI={invitationAttachmentOriginal?.['@id']}
                            filetypes={['application/pdf']}
                        />
                    </Box>
                    {
                        invitationAttachments.map((attachment, index) => (
                            <Box display={translationLanguage === attachment.language ? 'block' : 'none'}
                                 key={attachment.language}>
                                <ImageUpload
                                    ref={attachment.ref}
                                    setImageIRI={(value) => updateInvitationAttachments(index, 'file', value)}
                                    showError={showError}
                                    catchErrors={catchErrors}
                                    setUploadStatus={(value) => updateInvitationAttachments(index, 'upload', value)}
                                    originalIRI={''}
                                    filetypes={['application/pdf']}
                                />
                            </Box>
                        ))
                    }
                </Grid>
                {
                    (
                        (!translationLanguage && !!invitationAttachmentOriginal?.contentUrl) ||
                        findOriginalTranslationAttachment(translationLanguage, false)?.contentUrl
                    ) &&
                    <Grid item xs={12}>
                        <a href={config.apiHost + (!translationLanguage ? invitationAttachmentOriginal.contentUrl : findOriginalTranslationAttachment(translationLanguage, false).contentUrl)}
                           target={"_blank"}
                           rel="noreferrer">{config.apiHost + (!translationLanguage ? invitationAttachmentOriginal.contentUrl : findOriginalTranslationAttachment(translationLanguage, false).contentUrl)}</a>
                    </Grid>
                }
            </React.Fragment>
        )
    }, [invitationAttachmentOriginal, catchErrors, findOriginalTranslationAttachment, invitationAttachments, translationLanguage]);

    const getContent = () => {
        if (loadingData || !countries.length || !customers.length || !languages.length || !statuses.length) {
            return null;
        }

        const MainForm = (
            <>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'name'}
                        defaultValue={name}
                        setDefaultValue={setName}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('project_name', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            required: true,
                            id: `project-create--name`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant={'outlined'} className={'project-create--form-control'}
                                 id={'project-create-customer'} required>
                        <InputLabel>
                            {GlobalTransIntl('customer', intl)}
                        </InputLabel>
                        <Select
                            labelId="project-create--customer-select"
                            value={customer}
                            onChange={(event) => {
                                setCustomer(event.target.value);
                            }}
                            label={GlobalTransIntl('customer', intl)}
                            IconComponent={selectIconComponent}
                        >
                            {
                                customers.map((item) =>
                                    <MenuItem value={item['@id']} key={item.id}
                                              data-name={item.name}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant={'outlined'} className={'project-create--form-control'}
                                 id={'project-create-country'} required>
                        <InputLabel>
                            {GlobalTransIntl('country', intl)}
                        </InputLabel>
                        <Select
                            labelId="project-create--country-select"
                            value={country}
                            onChange={(event) => {
                                setCountry(event.target.value);
                            }}
                            label={GlobalTransIntl('country', intl)}
                            IconComponent={selectIconComponent}
                        >
                            {
                                countries.map((item) =>
                                    <MenuItem value={item['@id']} key={item.id}
                                              data-name={item.name}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant={'outlined'} className={'project-create--form-control'}
                                 id={'project-create-status'} required>
                        <InputLabel>
                            {GlobalTransIntl('status', intl)}
                        </InputLabel>
                        <Select
                            labelId="project-create--status-select"
                            value={status}
                            onChange={(event) => {
                                setStatus(event.target.value);
                            }}
                            label={GlobalTransIntl('status', intl)}
                            IconComponent={selectIconComponent}
                        >
                            {
                                statuses.map((item) =>
                                    <MenuItem value={item['@id']} key={item.id}
                                              data-name={item.name}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('slogan_top', intl)}
                               id={'project-create--slogan-top'}
                               autoComplete={'off'}
                               variant="outlined"
                               onChange={(e) => {
                                   setSloganTop(e.target.value)
                               }}
                               value={sloganTop}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('slogan_bottom', intl)}
                               id={'project-create--slogan-bottom'}
                               autoComplete={'off'}
                               variant="outlined"
                               onChange={(e) => {
                                   setSloganBottom(e.target.value)
                               }}
                               value={sloganBottom}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('provided_by', intl)}
                               id={'project-create--provided-by'}
                               variant="outlined"
                               onChange={(e) => {
                                   setProvidedBy(e.target.value)
                               }}
                               value={providedBy}
                    />
                </Grid>
                <Box component={Grid} item md={6} sx={{display: {xs: 'none', md: 'block'}}}></Box>
                <Grid item xs={12} md={6}>
                    <TextField error={!validReceiver}
                               label={GlobalTransIntl('receiver', intl)}
                               id={'project-create--receiver'}
                               variant="outlined"
                               onChange={(e) => {
                                   setReceiver(e.target.value)
                               }}
                               helperText={GlobalTransIntl('error_field_email', intl)}
                               value={receiver}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant={'outlined'} className={'project-create--form-control'}
                                 id={'project-create-receiver-language'}>
                        <InputLabel>
                            {GlobalTransIntl('receiver_language', intl)}
                        </InputLabel>
                        <Select
                            labelId="project-create--language-select"
                            value={language}
                            onChange={(event) => {
                                setLanguage(event.target.value);
                            }}
                            label={GlobalTransIntl('language', intl)}
                            IconComponent={selectIconComponent}
                        >
                            <MenuItem value="">
                                {GlobalTransIntl('translation_default', intl)}
                            </MenuItem>
                            {
                                languages.map((item) =>
                                    <MenuItem value={item['@id']} key={item.id}
                                              data-name={item.name}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkboxDeactivateDataProtection}
                                onChange={(e) => {
                                    setCheckboxDeactivateDataProtection(e.target.checked)
                                }}
                                name="deactivate_data_protection_checkbox"
                                color="primary"
                            />
                        }
                        label={GlobalTransIntl('deactivate_data_protection', intl)}
                    />
                </Grid>
                {
                    !checkboxDeactivateDataProtection &&
                    (
                        <>
                            <Grid item xs={12} md={6}>
                                <TranslationsInput
                                    field={'dataProtection'}
                                    defaultValue={dataProtection}
                                    setDefaultValue={setDataProtection}
                                    translations={translations}
                                    language={translationLanguage}
                                    label={GlobalTransIntl('data_protection', intl)}
                                    setTranslations={setTranslations}
                                    attributes={{
                                        multiline: true,
                                        minRows: 4,
                                        id: `project-create--data-protection`,
                                        autoComplete: 'off'
                                    }}
                                />
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('expire_pin_days', intl)}
                               id={'project-create--expire-pin-days'}
                               variant="outlined"
                               onChange={(e) => {
                                   inputOnlyInteger(e, setExpiredPinDays)
                               }}
                               InputProps={{
                                   endAdornment: GlobalTransIntl('day(s)', intl),
                               }}
                               value={expiredPinDays}
                    />
                </Grid>
            </>
        )

        const Reminder = (
            <>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('reminder', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('reminder_one', intl)}
                               id={'project-create--reminder-one'}
                               variant="outlined"
                               onChange={(e) => {
                                   inputOnlyInteger(e, setReminderOne)
                               }}
                               InputProps={{
                                   endAdornment: GlobalTransIntl('day(s)', intl),
                               }}
                               value={reminderOne}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('reminder_two', intl)}
                               id={'project-create--reminder-two'}
                               variant="outlined"
                               onChange={(e) => {
                                   inputOnlyInteger(e, setReminderTwo)
                               }}
                               InputProps={{
                                   endAdornment: GlobalTransIntl('day(s)', intl),
                               }}
                               value={reminderTwo}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={GlobalTransIntl('exclude_after', intl)}
                               id={'project-create--exclude-after'}
                               variant="outlined"
                               onChange={(e) => {
                                   inputOnlyInteger(e, setExcludeAfter)
                               }}
                               InputProps={{
                                   endAdornment: GlobalTransIntl('day(s)', intl),
                               }}
                               value={excludeAfter}
                    />
                </Grid>
                <Box component={Grid} item md={6} sx={{display: {xs: 'none', md: 'block'}}}></Box>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'reminderMailSubject'}
                        defaultValue={reminderMailSubject}
                        setDefaultValue={setReminderMailSubject}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('reminder_subject', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--reminder-subject`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                        <Grid item xs={12}>%link% - {GlobalTransIntl('pin_link', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'reminderMailContent'}
                        defaultValue={reminderMailContent}
                        setDefaultValue={setReminderMailContent}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('reminder_content', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--reminder-content`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Box component={Grid} item md={6} sx={{display: {xs: 'none', md: 'block'}}}></Box>
                <Grid item xs={6}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('reminder_three_sixty_focusperson', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('reminder_three_sixty_reference_giver', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'reminderMailFocuspersonSubject'}
                        defaultValue={reminderMailFocuspersonSubject}
                        setDefaultValue={setReminderMailFocuspersonSubject}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('reminder_subject', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--reminder-focusperson-subject`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'reminderMailReferenceGiverSubject'}
                        defaultValue={reminderMailReferenceGiverSubject}
                        setDefaultValue={setReminderMailReferenceGiverSubject}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('reminder_subject', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--reminder-reference-giver-subject`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                        <Grid item xs={12}>%link% - {GlobalTransIntl('pin_link', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                        <Grid item xs={12}>%link% - {GlobalTransIntl('pin_link', intl)}</Grid>
                        <Grid item xs={12}>%name_focusperson% - {GlobalTransIntl('name_focusperson', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'reminderMailFocuspersonContent'}
                        defaultValue={reminderMailFocuspersonContent}
                        setDefaultValue={setReminderMailFocuspersonContent}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('reminder_content', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--reminder-focusperson-content`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'reminderMailReferenceGiverContent'}
                        defaultValue={reminderMailReferenceGiverContent}
                        setDefaultValue={setReminderMailReferenceGiverContent}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('reminder_content', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--reminder-reference-giver-content`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
            </>
        );

        const Invitations = (
            <>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('invitations', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'invitationSubject'}
                        defaultValue={invitationSubject}
                        setDefaultValue={setInvitationSubject}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('invitation_subject', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--invitation-subject`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Box component={Grid} item md={6} display={{xs: 'none', md: 'block'}}/>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                        <Grid item xs={12}>%link% - {GlobalTransIntl('pin_link', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'invitation'}
                        defaultValue={invitation}
                        setDefaultValue={setInvitation}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('invitation', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--invitation`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {GlobalTransIntl('invitation_attachment', intl)}
                </Grid>
                {renderInvitationAttachmentUploads()}
            </>
        );

        const ResultMail = (
            <>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('result_mail', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'resultMailSubject'}
                        defaultValue={resultMailSubject}
                        setDefaultValue={setResultMailSubject}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('result_mail_subject', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--result-mail-subject`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Box component={Grid} item md={6} display={{xs: 'none', md: 'block'}}/>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'resultMailContent'}
                        defaultValue={resultMailContent}
                        setDefaultValue={setResultMailContent}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('result_mail_content', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--result-mail-content`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
            </>
        );

        const ThreeSixtyDegree = (
            <>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('three_sixty_degree', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'threeSixtySubjectFocusperson'}
                        defaultValue={threeSixtySubjectFocusperson}
                        setDefaultValue={setThreeSixtySubjectFocusperson}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('three_sixty_subject_focusperson', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--three-sixty-subject-focusperson`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'threeSixtySubjectReferenceGiver'}
                        defaultValue={threeSixtySubjectReferenceGiver}
                        setDefaultValue={setThreeSixtySubjectReferenceGiver}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('three_sixty_subject_reference_giver', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--three-sixty-subject-reference-giver`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                        <Grid item xs={12}>%link% - {GlobalTransIntl('pin_link', intl)}</Grid>
                        <Grid item xs={12}>%name_focusperson% - {GlobalTransIntl('name_focusperson', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'threeSixtyInviteFocusperson'}
                        defaultValue={threeSixtyInviteFocusperson}
                        setDefaultValue={setThreeSixtyInviteFocusperson}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('three_sixty_invite_focusperson', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--invite-focusperson`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TranslationsInput
                        field={'threeSixtyReferenceGiver'}
                        defaultValue={threeSixtyReferenceGiver}
                        setDefaultValue={setThreeSixtyReferenceGiver}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('three_sixty_invite_referencegiver', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            multiline: true,
                            minRows: 4,
                            id: `project-create--invite-referencegiver`,
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"h2"} align={"center"}>
                        {GlobalTransIntl('three_sixty_report', intl)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {GlobalTransIntl('cover_sheet_logo', intl)}
                </Grid>
                <Grid item xs={12}>
                    <ImageUpload
                        ref={threeSixtyCoverSheetLogoRef}
                        setImageIRI={setThreeSixtyCoverSheetLogo}
                        showError={showError}
                        catchErrors={catchErrors}
                        setUploadStatus={setThreeSixtyCoverSheetLogoUpload}
                        originalIRI={threeSixtyCoverSheetLogoOriginal?.['@id']}
                    />
                </Grid>
                {
                    !!threeSixtyCoverSheetLogoOriginal?.contentUrl &&
                    <Grid item xs={12}>
                        <img src={config.apiHost + threeSixtyCoverSheetLogoOriginal.contentUrl} alt={''}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%name% - {GlobalTransIntl('name_focusperson', intl)}</Grid>
                        <Grid item xs={12}>%salutation[male,female,various]%
                            - {GlobalTransIntl('gender_salutation', intl)}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TranslationsInput
                        field={'threeSixtyIntroduction'}
                        defaultValue={threeSixtyIntroduction}
                        setDefaultValue={setThreeSixtyIntroduction}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('introduction', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--three-sixty-introduction`,
                            autoComplete: 'off',
                            multiline: true,
                            minRows: 12,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TranslationsInput
                        field={'threeSixtyDisclaimer'}
                        defaultValue={threeSixtyDisclaimer}
                        setDefaultValue={setThreeSixtyDisclaimer}
                        translations={translations}
                        language={translationLanguage}
                        label={GlobalTransIntl('disclaimer', intl)}
                        setTranslations={setTranslations}
                        attributes={{
                            id: `project-create--three-sixty-disclaimer`,
                            autoComplete: 'off',
                            multiline: true,
                            minRows: 12,
                        }}
                    />
                </Grid>
            </>
        );

        return (
            <Form className={'project-create--form'} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                    {MainForm}
                    {Reminder}
                    {Invitations}
                    {ResultMail}
                    {ThreeSixtyDegree}
                </Grid>
                <Box mt={2}>
                    <Button variant="primary" type="submit" className='project-create--submit'>
                        {
                            (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                        }
                    </Button>
                </Box>
            </Form>
        );
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='project.edit.headline' defaultMessage='Edit project'/>
        }

        return <FormattedMessage id='project.create.headline' defaultMessage='Create project'/>
    }

    return (
        <Paper>
            <Spinner show={loadingData} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
            </Box>
            {
                !loadingData &&
                <React.Fragment>
                    <CardContent>
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <h1>
                                        <Headline/>
                                    </h1>
                                </Grid>
                                <Grid item>
                                    <TranslationLanguages selectedLanguage={translationLanguage}
                                                          setLanguage={setTranslationLanguage}/>
                                </Grid>
                            </Grid>
                        </Box>
                        {
                            getContent()
                        }
                    </CardContent>
                </React.Fragment>
            }
        </Paper>
    );
}

export default ProjectForm;