import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Box, Button, createTheme, MuiThemeProvider} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import {green} from "@material-ui/core/colors";
import axios from "axios";
import config from "../../config/config";
import PropTypes from "prop-types";
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import {Alert} from "react-bootstrap";

const ImageUpload = forwardRef((props, ref) => {
    const intl = useIntl();
    const {state} = useStore();
    const uploadRef = useRef();
    const acceptedFiletypes = props?.filetypes?.length ? props.filetypes : ['image/*'];

    const [cancelToken] = useState(axios.CancelToken.source());

    const [upload, setUpload] = useState('');
    const [uploadFiles, setUploadFiles] = useState('');
    const [error, setError] = useState('');

    const greenButton = createTheme({palette: {secondary: green}});

    const handleUploadChange = () => {
        setError('');

        if (uploadRef.current?.files.length) {
            const file = uploadRef.current?.files[0];
            const size = file.size / 1000000 // it's in Megabytes

            if (size > config.uploadFileSizeMb) {
                setError(GlobalTransIntl('error_max_file_size', intl, {size: config.uploadFileSizeMb}));
                return
            }

            setUploadFiles(uploadRef.current.files);
            setUpload(uploadRef.current.files[0].name);
        }
    }

    const UploadButtonLabel = () => {
        return upload || GlobalTrans('upload');
    }

    const sendFileEvent = () => {
        let formData = new FormData();

        formData.append('file', uploadFiles[0]);

        // Files will be sent
        axios.post(config.apiUrl + `/media_objects`, formData, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'Content-Type': 'multipart/form-data'}
        }))
            .then(res => {
                if (res.data) {
                    if (props.returnType === 'object') {
                        props.setImageIRI(res.data);
                    } else {
                        props.setImageIRI(res.data['@id']);
                    }
                } else {
                    props.showError();
                }

                props.setUploadStatus(true);
            })
            .catch(props.catchErrors);
    }

    const deleteOriginal = () => {
        axios.delete(config.apiHost + props.originalIRI, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(() => sendFileEvent());
    }

    useImperativeHandle(ref, () => ({
        sendFile: () => {
            if (!upload) {
                props.setUploadStatus(true);
            } else if (props.originalIRI) {
                deleteOriginal();
            } else {
                sendFileEvent();
            }
        },
        reset: () => {
            setUpload('');
            setUploadFiles('');
        },
        hasFile: () => {
            return !!uploadFiles;
        }
    }));

    return (
        <div>
            <Box display={'none'}>
                <input
                    ref={uploadRef}
                    onChange={handleUploadChange}
                    accept={acceptedFiletypes}
                    type="file"
                    className={'hide'}
                />
            </Box>

            <MuiThemeProvider theme={greenButton}>
                <Button
                    variant="contained"
                    color={(upload) ? 'secondary' : 'primary'}
                    startIcon={<FontAwesomeIcon icon={faCloudArrowUp}/>}
                    onClick={() => {
                        uploadRef.current?.click()
                    }}
                >
                    <UploadButtonLabel/>
                </Button>
                {
                    (error) &&
                    <Box mt={2}>
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    </Box>
                }
            </MuiThemeProvider>
        </div>
    );
});

ImageUpload.propTypes = {
    setImageIRI: PropTypes.func.isRequired,
    showError: PropTypes.func.isRequired,
    catchErrors: PropTypes.func.isRequired,
    setUploadStatus: PropTypes.func.isRequired,
    originalIRI: PropTypes.string,
    filetypes: PropTypes.array,
    returnType: PropTypes.oneOf(['id', 'object'])
}

export default ImageUpload;