import React, {useCallback, useState} from 'react';
import {Box, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import TranslationsInput from "../../translations/TranslationsInput";
import {useIntl} from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo";

const MailLayoutElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);

    const [icon, setIcon] = React.useState(props.icon || 0);
    const [from, setFrom] = useState(props.from || '');
    const [to, setTo] = useState(props.to || '');
    const [subject, setSubject] = useState(props.subject || '');
    const [date, setDate] = useState(props.date || '');
    const [info, setInfo] = useState(props.info || '');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            icon,
            from,
            to,
            subject,
            date,
            info,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, icon, from, to, subject, date, info, translations]);

    const getIconById = (id) => {
        switch (id) {
            case 1:
                return <FontAwesomeIcon icon={faEnvelope}/>;
            case 2:
                return <FontAwesomeIcon icon={faVideo}/>;
            default:
                return '';
        }
    }

    const changeIcon = (event) => {
        setIcon(parseInt(event.target.value));
    };

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{GlobalTransIntl('icon', intl)}</FormLabel>
                    <RadioGroup aria-label={GlobalTransIntl('icon', intl)} name="icon" value={icon}
                                onChange={changeIcon}>
                        <FormControlLabel value={1} control={<Radio/>} label={getIconById(1)}/>
                        <FormControlLabel value={2} control={<Radio/>} label={getIconById(2)}/>
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box mb={2}>
                <TranslationsInput
                    field={'from'}
                    defaultValue={from}
                    setDefaultValue={setFrom}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('from')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Box>
            <Box mb={2}>
                <TranslationsInput
                    field={'to'}
                    defaultValue={to}
                    setDefaultValue={setTo}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('to')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Box>
            <Box mb={2}>
                <TranslationsInput
                    field={'subject'}
                    defaultValue={subject}
                    setDefaultValue={setSubject}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('subject')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Box>
            <Box mb={2}>
                <TranslationsInput
                    field={'date'}
                    defaultValue={date}
                    setDefaultValue={setDate}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('date')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Box>
            <Box mb={2}>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                        <Grid item xs={12}>%time% - {GlobalTransIntl('time', intl)}</Grid>
                    </Grid>
                </Box>
                <TranslationsInput
                    field={'info'}
                    defaultValue={info}
                    setDefaultValue={setInfo}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('info')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        multiline: true,
                        minRows: 4,
                    }}
                />
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            <Typography>{GlobalTransIntl('icon', intl)}: {getIconById(props.icon)}</Typography>
            <Typography>{GlobalTransIntl('from', intl)}: {props.from}</Typography>
            <Typography>{GlobalTransIntl('to', intl)}: {props.to}</Typography>
            <Typography>{GlobalTransIntl('subject', intl)}: {props.subject}</Typography>
            <Typography>{GlobalTransIntl('date', intl)}: {props.date}</Typography>
            <Box whiteSpace={'pre-line'}>
                <Typography>{GlobalTransIntl('info', intl)}:<br/> {props.info}</Typography>
            </Box>
        </Box>
    );
};

MailLayoutElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const MailLayoutElementConfig = {
    title: 'mail_layout',
    icon: 0,
    from: '',
    to: '',
    subject: '',
    date: '',
    info: '',
    translations: [],
}

export default MailLayoutElement;