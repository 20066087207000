import React, {useCallback} from 'react';
import {GlobalTransIntl} from "./GlobalTrans";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";

const CustomDialog = (props) => {
    const intl = useIntl();

    const agreeDialog = useCallback(() => {
        props.agreeFunction();
        props.closeFunction();
    }, [props]);

    return (
        <Dialog
            open={props.open}
            onClose={props.closeFunction}
        >
            <Box p={1}>
                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.closeFunction} variant={"contained"}>
                        {GlobalTransIntl('no', intl)}
                    </Button>
                    <Button
                        id={'custom-dialog--yes'}
                        onClick={agreeDialog}
                        color="primary"
                        variant={"contained"}
                        autoFocus
                    >
                        {GlobalTransIntl('yes', intl)}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

CustomDialog.propTypes = {
    text: PropTypes.string.isRequired,
    agreeFunction: PropTypes.func.isRequired,
    closeFunction: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default CustomDialog;