import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl.macro";
import Notifications from "../notifications/Notifications";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Grid} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import Api from "../../helper/Api";
import {
    checkMultipleAxios,
    helperCatchErrors,
    inputOnlyFloat,
    scrollToTop,
    selectIconComponent
} from "../../helper/Helper";
import Spinner from "../spinner/Spinner";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config/config";

const ForcedChoiceWeightingForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [loadingData, setLoadingData] = useState(true);

    const [dimensions, setDimensions] = useState('');
    const [selectedDimension, setSelectedDimension] = useState(state.editForm?.dimension?.['@id'] || '');
    const [loadingDimensions, setLoadingDimensions] = useState(true);

    const [normgroups, setNormgroups] = useState('');
    const [selectedNormgroup, setSelectedNormgroup] = useState(state.editForm?.normgroup?.['@id'] || '');
    const [loadingNormgroups, setLoadingNormgroups] = useState(true);

    const [questionaireWeighting, setQuestionaireWeighting] = useState(state.editForm.questionaireWeighting || '');
    const [forcedChoiceWeighting, setForcedChoiceWeighting] = useState(state.editForm.forcedChoiceWeighting || '');

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const getDimensions = useCallback(() => {
        Api.getApi(
            'dimensions',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setDimensions(res.data['hydra:member']);
                }

                setLoadingDimensions(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setNormgroups(res.data['hydra:member']);
                }

                setLoadingNormgroups(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getDimensions();
            getNormgroups();

            setMounted(true);
        }
    }, [mounted, getDimensions, getNormgroups, dispatch, editForm]);

    useEffect(() => {
        let checkAllAxios = checkMultipleAxios(
            [
                loadingDimensions,
                loadingNormgroups
            ],
            [
                {
                    name: 'dimension',
                    value: dimensions
                },
                {
                    name: 'normgroup',
                    value: normgroups
                },
            ],
            intl
        )

        if (checkAllAxios.length) {
            showError(checkAllAxios);
            setLoadingData(false);
        } else if (checkAllAxios === true) {
            setLoadingData(false);
        }
    }, [loadingDimensions, loadingNormgroups, dimensions, normgroups, intl]);

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        let data = {
            dimension: selectedDimension,
            normgroup: selectedNormgroup,
            questionaireWeighting: parseFloat(questionaireWeighting),
            forcedChoiceWeighting: parseFloat(forcedChoiceWeighting),
        }

        if (editForm) {
            axios.put(config.apiUrl + `/forced_choice_weightings/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/forced_choice_weightings`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data) {
                        setQuestionaireWeighting('');
                        setForcedChoiceWeighting('');
                        setSelectedDimension('');
                        setSelectedNormgroup('');

                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }

    const getCardContent = () => {
        if (loadingData || !dimensions.length || !normgroups.length) {
            return null;
        }

        return (
            <CardContent>
                <Form className={`forced_choice_weighting--create-form`} onSubmit={sendSubmit}>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant={'outlined'}
                                             className={'forced_choice_weighting-create--form-control'}
                                             id={'forced_choice_weighting-create-dimension'} required>
                                    <InputLabel>
                                        {GlobalTransIntl('dimension', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId="forced_choice_weighting-create--dimension-select"
                                        value={selectedDimension}
                                        onChange={(event) => {
                                            setSelectedDimension(event.target.value);
                                        }}
                                        label={GlobalTransIntl('dimension', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            dimensions.map((item) =>
                                                <MenuItem value={item['@id']} key={item.id}
                                                          data-name={item.name}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant={'outlined'}
                                             className={'forced_choice_weighting-create--form-control'}
                                             id={'forced_choice_weighting-create-normgroup'} required>
                                    <InputLabel>
                                        {GlobalTransIntl('normgroup', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId="forced_choice_weighting-create--normgroup-select"
                                        value={selectedNormgroup}
                                        onChange={(event) => {
                                            setSelectedNormgroup(event.target.value);
                                        }}
                                        label={GlobalTransIntl('normgroup', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            normgroups.map((item) =>
                                                <MenuItem value={item['@id']} key={item.id}
                                                          data-name={item.name}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label={GlobalTransIntl('questionaire_weighting', intl)}
                                           id={`forced_choice_weighting-create--name`}
                                           autoComplete={'off'}
                                           variant="outlined"
                                           onChange={(e) => inputOnlyFloat(e, () => setQuestionaireWeighting(e.target.value))}
                                           value={questionaireWeighting}
                                           required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label={GlobalTransIntl('forced_choice_weighting', intl)}
                                           id={`forced_choice_weighting-create--name`}
                                           autoComplete={'off'}
                                           variant="outlined"
                                           onChange={(e) => inputOnlyFloat(e, () => setForcedChoiceWeighting(e.target.value))}
                                           value={forcedChoiceWeighting}
                                           required
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button variant="primary" type="submit" className={`forced_choice_weighting--create-submit`}>
                        {
                            (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                        }
                    </Button>
                </Form>
            </CardContent>
        );
    };

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='forced_choice_weighting.edit.headline'
                                     defaultMessage='Edit forced choice weighting'/>
        }

        return <FormattedMessage id='forced_choice_weighting.create.headline'
                                 defaultMessage='Create forced choice weighting'/>
    }

    return (
        <React.Fragment>
            <h1>
                <Headline/>
            </h1>
            <Notifications
                success={notificationSuccess}
                error={notificationError}
                errorMessage={GlobalTrans(notificationErrorMessage)}
            />
            <Row>
                <Col cs={12} lg={6}>
                    <Paper>
                        <Spinner show={loadingData} rowClass={'p-5'}/>
                        {
                            getCardContent()
                        }
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ForcedChoiceWeightingForm;