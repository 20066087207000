import React, {useCallback, useState} from 'react';
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import PropTypes from "prop-types";
import {Box, Button, IconButton, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import ModuleElementDimension from "../../../helper/ModuleElementDimension";
import Form from "react-bootstrap/cjs/Form";
import TranslationsInput from "../../translations/TranslationsInput";
import ModuleElementOptions from "../../../helper/ModuleElementOptions";
import {v4 as uuidv4} from "uuid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";

const RandomMultipleChoiceElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);

    const [dimension, setDimension] = useState(props.dimension || '');
    const [answers, setAnswers] = useState(props.answer || {});

    const [elements, setElements] = useState(props.elements);
    const [questions, setQuestions] = useState(props.questions);
    const [options, setOptions] = useState(props.options);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        const data = {
            elements,
            questions,
            options,
            translations,
        };

        if (!props.checkboxDimension) {
            data.dimension = dimension;
        }

        props.updateAnswer(props.id, answers);
        props.updateContent(props, data);
    }, [dimension, options, props, questions, answers, translations, elements]);

    const setElementQuestion = (elementId, value) => {
        setQuestions(prev => ({
            ...prev,
            [elementId]: value
        }));
    }

    const removeElementQuestions = (elementId) => {
        setQuestions(prev => {
            const newQuestions = {...prev};

            delete newQuestions[elementId];

            return newQuestions;
        })
    }

    const setElementOptions = (elementId, value) => {
        setOptions(prev => ({
            ...prev,
            [elementId]: value
        }));
    }

    const removeElementOptions = (elementId) => {
        setOptions(prev => {
            const newOptions = {...prev};

            delete newOptions[elementId];

            return newOptions;
        })
    }

    const setElementAnswer = (elementId, value) => {
        setAnswers(prev => ({
            ...prev,
            [elementId]: value
        }));
    }

    const addElement = () => {
        setElements(prev => {
            const newElements = [...prev];
            const newUUuid = uuidv4();
            setElementQuestion(newUUuid, '');
            setElementOptions(newUUuid, ['']);
            setElementAnswer(newUUuid, '');

            newElements.push(newUUuid);

            return newElements;
        });
    }

    const removeElement = (elementIndex) => {
        const elementId = elements[elementIndex];

        setElements(prev => {
            const newElements = [...prev];

            newElements.splice(elementIndex, 1);

            return newElements;
        });

        removeElementOptions(elementId);
        removeElementQuestions(elementId);
    }

    const modalBody = (
        <React.Fragment>
            <ModuleElementDimension
                dimension={dimension}
                setDimension={setDimension}
                showDimension={!props.checkboxDimension}
                dimensions={props.dimensions}
            />

            {
                elements.length > 0 &&
                elements.map((element, index) => (
                    <Box key={element} mb={2}>
                        <IconButton onClick={() => removeElement(index)} title={GlobalTransIntl('delete', intl)}>
                            <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                        </IconButton>
                        <Form.Group controlId="name">
                            <TranslationsInput
                                field={'questions_' + element}
                                defaultValue={questions[element]}
                                setDefaultValue={(value) => setElementQuestion(element, value)}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('text', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    required: true,
                                    autoComplete: 'off',
                                }}
                            />
                        </Form.Group>

                        <Box mb={2}>
                            <Typography>{GlobalTransIntl('options', intl)}</Typography>
                        </Box>
                        <ModuleElementOptions
                            label={'options'}
                            options={options[element]}
                            setOptions={(value) => setElementOptions(element, value)}
                            answer={answers[element]}
                            setAnswer={(value) => setElementAnswer(element, value)}
                            translations={translations}
                            setTranslations={setTranslations}
                            translationLanguage={translationLanguage}
                            translationsField={'options_' + element}
                        />
                    </Box>
                ))
            }
            <Box mb={2}>
                <Button variant={"contained"} startIcon={<FontAwesomeIcon icon={faPlus} size={"xs"}/>}
                        onClick={addElement}>
                    {GlobalTransIntl('add_element', intl)}
                </Button>
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translationLanguage={translationLanguage}
                setTranslationLanguage={setTranslationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.elements.map((element) => (
                    <div key={element}>
                        {
                            props.questions[element].length > 0 &&
                            <Typography>{props.questions[element]}</Typography>
                        }
                        {
                            props.options[element].length > 0 &&
                            <List>
                                {
                                    props.options[element].map((option, index) =>
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={option}
                                                primaryTypographyProps={{className: (index === answers[element]) ? 'font-weight-bold' : ''}}
                                            />
                                        </ListItem>
                                    )
                                }
                            </List>
                        }
                    </div>
                ))
            }
        </Box>
    );
};

RandomMultipleChoiceElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    checkboxDimension: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
};

export const RandomMultipleChoiceConfig = {
    title: 'random_multiple_choice',
    elements: [],
    questions: {},
    options: {},
    translations: [],
    validationType: 'option_random',
}

export default RandomMultipleChoiceElement;