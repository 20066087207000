import React, {useCallback, useEffect, useState} from 'react';
import {Box, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import TranslationsInput from "../translations/TranslationsInput";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import FormControl from "@material-ui/core/FormControl";
import {helperCatchErrors, inputStepper, scrollToTop, selectIconComponent} from "../../helper/Helper";
import TextField from "@material-ui/core/TextField";
import MaterialButton from "@material-ui/core/Button";
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import Api from "../../helper/Api";
import Spinner from "../spinner/Spinner";
import Notifications from "../notifications/Notifications";
import PropTypes from "prop-types";

const Interpretations = (props) => {
    const {state} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [scales, setScales] = useState([]);
    const [loadingScales, setLoadingScales] = useState(true);

    const [addScore, setAddScore] = useState('');
    const [selectedScale, setSelectedScale] = useState('');

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const getScales = useCallback(() => {
        Api.getApi(
            'scales',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setScales(res.data['hydra:member']);
                }

                setLoadingScales(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        if (!mounted) {
            getScales();

            setMounted(true);
        }
    }, [mounted, getScales]);

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );

            return result;
        }, {});
    };

    const findInterpretation = useCallback((score, scale) => {
        return props.interpretations.find((interpretation) => interpretation.score === parseFloat(score) && interpretation.scale === scale);
    }, [props.interpretations]);

    const addInterpretation = useCallback(() => {
        if (!addScore || !selectedScale || findInterpretation(addScore, selectedScale)) {
            return false;
        }

        props.setInterpretations(prev => ([
            ...prev,
            {
                score: parseFloat(addScore),
                scale: selectedScale,
                text: '',
                translations: [],
            }
        ]));

        setAddScore('');
    }, [addScore, findInterpretation, selectedScale, props]);

    const updateInterpretations = useCallback((score, scale, field, value) => {
        const foundInterpretation = findInterpretation(score, scale);

        if (foundInterpretation) {
            const index = props.interpretations.indexOf(foundInterpretation);

            props.setInterpretations(prev => {
                const newInterpretations = [...prev];

                newInterpretations[index] = {
                    ...newInterpretations[index],
                    [field]: value
                };

                return newInterpretations;
            });
        }
    }, [findInterpretation, props]);

    const removeInterpretation = useCallback((score, scale) => {
        const newInterpretations = props.interpretations.filter((interpretation) => interpretation.score !== parseFloat(score) || interpretation.scale !== scale);

        props.setInterpretations(newInterpretations);
    }, [props]);

    return (
        <>
            <Spinner show={loadingScales} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                (!loadingScales && scales.length > 0) &&
                <>
                    {
                        (props.interpretations.length > 0) &&
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>{GlobalTransIntl('score', intl)}</Grid>
                                <Grid item xs>{GlobalTransIntl('score_text', intl)}</Grid>
                            </Grid>
                            {
                                Object.keys(groupBy(props.interpretations, 'scale')).map((key) => (
                                    <React.Fragment key={key}>
                                        <Grid container spacing={2} justifyContent={'center'}>
                                            <Grid item>
                                                <h2>{scales.find(scale => scale['@id'] === key).name}</h2>
                                            </Grid>
                                        </Grid>
                                        {
                                            groupBy(props.interpretations, 'scale')[key].map((interpretation) => (
                                                <Grid container spacing={2}
                                                      justifyContent={'center'}
                                                      key={interpretation.score}>
                                                    <Grid item xs={1} className={'align-self--center'}>
                                                        {interpretation.score}
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TranslationsInput
                                                            setDefaultValue={(value) => updateInterpretations(interpretation.score, interpretation.scale, 'text', value)}
                                                            field={'text'}
                                                            defaultValue={interpretation.text}
                                                            translations={interpretation.translations}
                                                            language={props.translationLanguage}
                                                            label={GlobalTransIntl('text', intl)}
                                                            setTranslations={(value) => updateInterpretations(interpretation.score, interpretation.scale, 'translations', value)}
                                                            attributes={{
                                                                multiline: true,
                                                                minRows: 4,
                                                                required: true
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item className={'align-self--center'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                removeInterpretation(interpretation.score, interpretation.scale)
                                                            }}
                                                            aria-label={GlobalTransIntl('title_delete', intl)}
                                                            title={GlobalTransIntl('title_delete', intl)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt}
                                                                             size={"xs"}/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </Box>
                    }
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box sx={{minWidth: 120}}>
                                <FormControl variant={'outlined'}>
                                    <InputLabel id="dimensions-create--select-label">
                                        {GlobalTransIntl('scales', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId="dimensions-create--select-label"
                                        id="dimensions-create-select"
                                        value={selectedScale}
                                        label={GlobalTransIntl('scales', intl)}
                                        onChange={(event) => setSelectedScale(event.target.value)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            scales.map((scale, index) => (
                                                <MenuItem key={index} value={scale['@id']}>
                                                    {scale.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={GlobalTransIntl('score', intl)}
                                autoComplete={'off'}
                                variant="outlined"
                                onChange={(e) => {
                                    inputStepper(e, setAddScore, 0.5, 1, scales.find(scale => scale['@id'] === selectedScale).size)
                                }}
                                value={addScore}
                            />
                        </Grid>
                        <Grid item className={'align-self--center'}>
                            <MaterialButton
                                variant="contained"
                                color="default"
                                className={''}
                                startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                                onClick={addInterpretation}
                                aria-label={GlobalTransIntl('add_score', intl)}
                                title={GlobalTransIntl('add_score', intl)}
                            >
                                {GlobalTransIntl('add_score', intl)}
                            </MaterialButton>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
};

Interpretations.propTypes = {
    interpretations: PropTypes.array.isRequired,
    setInterpretations: PropTypes.func.isRequired,
    translationLanguage: PropTypes.string.isRequired,
}

export default Interpretations;