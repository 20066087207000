import React from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faSquareFull} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import GlobalTrans from "../../helper/GlobalTrans";
import config from "../../config/config";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";

function ThemeOverview() {
    const columns = [
        {
            field: 'customer', headerName: GlobalTrans('customer'), width: 250,
            valueGetter: (params) => params.value.name,
        },
        {field: 'name', headerName: GlobalTrans('name'), width: 250},
        {
            field: 'logo', headerName: GlobalTrans('logo'), width: 250,
            renderCell: (params) => {
                if (params.value) {
                    return <img className={'img-thumbnail'} src={config.apiHost + params.value} alt={''}/>
                }
            },
            valueGetter: (params) => (params.value) ? params.value.contentUrl : '',
        },
        {
            field: 'backgroundColor', headerName: GlobalTrans('background_color'), width: 180,
            renderCell: (params) => {
                if (params.value) {
                    return <FontAwesomeIcon icon={faSquareFull} color={params.value} title={params.value}
                                            className={'fa-3x'}/>
                }
            },
        },
        {
            field: 'fontColor', headerName: GlobalTrans('font_color'), width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <FontAwesomeIcon icon={faSquareFull} color={params.value} title={params.value}
                                            className={'fa-3x'}/>
                }
            },
        },
        {
            field: 'fontFamily', headerName: GlobalTrans('font_family'), width: 200,
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{fontFamily: params.value}}>{params.value}</span>
                }
            },
        },
    ];

    const onClick = (props) => {
        console.log("edit", props);
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={() => onClick(props)} aria-label={GlobalTrans('title_edit')}
                        title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'themes'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'select',
                        getUrl: 'customers',
                        name: 'customer',
                        label: GlobalTrans('customer'),
                        filterOperator: 'equals'
                    },
                    {
                        type: 'text',
                        name: 'name',
                        label: GlobalTrans('name')
                    }
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
}

export default ThemeOverview;