import React, {useCallback, useEffect, useState} from 'react';
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import {format} from "date-fns";
import LoadingDataGrid from "../../loadingDataGrid/LoadingDataGrid";
import Token from "../../../helper/Token";
import Notifications from "../../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import SelectProjectWrapper from "../../selectProject/selectProjectWrapper";
import {useStore} from "../../../store/useStore";
import {useIntl} from "react-intl";
import {Box, FormHelperText, Grid, Modal} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import config from "../../../config/config";
import ResultPdf from "../../resultPdf/resultPdf";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {helperCatchErrors, selectIconComponent} from "../../../helper/Helper";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import Api from "../../../helper/Api";
import axios from "axios";
import Spinner from "../../spinner/Spinner";
import ApiFilterBuilder from "../../../helper/ApiFilterBuilder";

const ThreeSixtyPins = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [cachedProject, setCachedProject] = useState(state.project ? state.project['@id'] : '');
    const [reload, setReload] = React.useState({0: false});
    const [languages, setLanguages] = useState(false);

    const [loadingResult, setLoadingResult] = useState(false);
    const [loadingLanguages, setLoadingLanguages] = useState(true);

    // Modal
    const [modalResultOpen, setModalResultOpen] = React.useState(false);
    const [modalLanguage, setModalLanguage] = React.useState('');
    const [modalPinId, setModalPinId] = React.useState(null);
    const [pinName, setPinName] = React.useState('');
    const [resultPdfBlob, setResultPdfBlob] = useState('');
    const [resultPdfName, setResultPdfName] = useState('');

    // Notification
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const assessmentUrl = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ],
        'procedures'
    );

    const [toolbarItems] = React.useState(Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN') ?
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTransIntl('pin', intl)
            },
            {
                type: 'text',
                name: 'fullName',
                label: GlobalTransIntl('name', intl)
            }
        ] :
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTransIntl('pin', intl)
            },
            {
                type: 'text',
                name: 'fullName',
                label: GlobalTransIntl('name', intl)
            },
            {
                type: 'select',
                getUrl: assessmentUrl,
                name: 'procedureObject',
                label: GlobalTrans('assessment'),
                filterOperator: 'equals'
            }
        ]
    );

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);

        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getLanguages = useCallback(() => {
        Api.getLanguages(
            (res) => {
                setLanguages(res.data['hydra:member']);
                setLoadingLanguages(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [state.token, catchErrors, cancelToken.token]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            getLanguages();
        }
    }, [mounted, getLanguages]);

    useEffect(() => {
        if (Object.keys(state.project).length && (cachedProject && cachedProject !== state.project['@id'])) {
            setCachedProject(state.project['@id']);
            setReload({0: true});
        } else if (Object.keys(state.project).length && !cachedProject) {
            setCachedProject(state.project['@id']);
        }
    }, [cachedProject, state.project]);

    const columns = [
        {field: 'token', headerName: GlobalTrans('pin'), width: 200},
        {
            field: 'procedureObject', headerName: GlobalTrans('assessment'), width: 150,
            valueGetter: (params) => params.value.name,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'firstName', headerName: GlobalTrans('first_name'), width: 140,
            valueGetter: (params) => {
                if (params.row?.user) {
                    return params.row.user?.firstName;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'lastName', headerName: GlobalTrans('last_name'), width: 140,
            valueGetter: (params) => {
                if (params.row?.user) {
                    return params.row.user?.lastName;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            hide: true,
            valueGetter: (params) => {
                if (params.row?.user) {
                    return `${params.row?.user?.firstName || ''} ${params.row?.user?.lastName || ''}`
                }

                return '';
            }
        },
        {
            field: 'comment', headerName: GlobalTrans('comment'), width: 160,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '';
                }
            }
        },
        {
            field: 'pinMails', headerName: GlobalTrans('receiver'), width: 160,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value.map((item) => item.email);
                } else {
                    return '-';
                }
            },
            renderCell: (params) => {
                if (params.value !== '-') {
                    return (
                        <div title={params.value}>
                            {params.value.map((item, key) => {
                                return ((key > 0) ? ',' : '') + item
                            })}
                        </div>
                    )
                }
            }
        },
        {
            field: 'startedAt',
            headerName: GlobalTrans('carried_out'),
            type: 'date',
            width: 140,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
        {field: 'finishedReferenceGiver', headerName: GlobalTrans('finished'), width: 150},
    ];

    const columnActions = (props) => {
        const splitFinished = props.row?.finishedReferenceGiver.split('/');

        const isFinished = splitFinished.length === 2 && splitFinished[0].trim() === splitFinished[1].trim();

        return (
            <IconButton
                onClick={() => handleOpen(props)}
                aria-label={GlobalTrans('title_result')}
                title={GlobalTrans('title_result')}
                disabled={!props.row?.user || !isFinished}
            >
                <FontAwesomeIcon icon={faChartBar} size={"xs"}/>
            </IconButton>
        );
    };

    const handleThreeSixtyCheckbox = () => {
        dispatch({
            type: 'setPinResult',
            payload: config.pinResults.normal
        })
    }

    const handleOpen = (props) => {
        const fullName = props.row.user.firstName + ' ' + props.row.user.lastName;

        setPinName(fullName);
        setModalPinId(props.id);
        setModalResultOpen(true);
    };

    const handleClose = () => {
        setModalLanguage('');

        setModalResultOpen(false);
        setLoadingResult(false);
        setResultPdfBlob('');
        setResultPdfName('');
    };

    const handleChangeLanguage = (event) => {
        setModalLanguage(event.target.value);
    };

    const handleOnClickPdf = () => {
        setLoadingResult(true);
    };

    const ModalElement = () => {
        return (
            <Modal
                open={modalResultOpen}
                onClose={handleClose}
                className={'MuiModal-root'}
            >
                <div className={'main-modal modal-size-md'}>
                    {
                        (
                            loadingResult &&
                            <ResultPdf
                                pinId={modalPinId}
                                pinName={pinName}
                                locale={modalLanguage || config.defaultLocale}
                                pdfUrl={'/result/pdf/threesixty'}
                                resultPdfBlob={resultPdfBlob}
                                setResultPdfBlob={setResultPdfBlob}
                                resultPdfName={resultPdfName}
                                setResultPdfName={setResultPdfName}
                            />
                        )
                        ||
                        <React.Fragment>
                            <h2 className={'text-align-center'}>
                                {GlobalTransIntl('pin_results_modal_headline', intl)}<br/> {pinName}
                            </h2>
                            <FormControl variant={'outlined'} className={'pin-result--form-control'}>
                                <InputLabel>
                                    {GlobalTrans('language')}
                                </InputLabel>
                                <Select
                                    labelId="pin-results--modal-language-select"
                                    value={modalLanguage}
                                    onChange={handleChangeLanguage}
                                    label={GlobalTrans('language')}
                                    IconComponent={selectIconComponent}
                                >
                                    <MenuItem value={''}
                                              data-name={GlobalTransIntl('translation_default', intl)}
                                    >
                                        {GlobalTransIntl('translation_default', intl)}
                                    </MenuItem>
                                    {
                                        languages.map((item, key) =>
                                            <MenuItem value={item.code} key={key}>{item.name}</MenuItem>
                                        )
                                    }
                                </Select>
                                <FormHelperText>{GlobalTransIntl('error_no_selected_language', intl)}</FormHelperText>
                            </FormControl>
                            <Grid container justifyContent={"center"}>
                                <Grid item>
                                    <IconButton color="primary"
                                                className={'pin-result--modal-button-pdf'}
                                                aria-label={GlobalTransIntl('result_pdf', intl)}
                                                title={GlobalTransIntl('result_pdf', intl)}
                                                component="span"
                                                onClick={handleOnClickPdf}>
                                        <FontAwesomeIcon icon={faFilePdf}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </div>
            </Modal>
        )
    };

    const content = (
        loadingLanguages
            ? <Spinner show={loadingLanguages} rowClass={'p-5'}/>
            : <Box position={'relative'}>
                <Box position={'absolute'} top={0} right={0} mt={2} mr={2} zIndex={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                onChange={handleThreeSixtyCheckbox}
                                name="three_sixty_checkbox"
                                color="primary"
                            />
                        }
                        label={GlobalTransIntl('three_sixty_pins', intl)}
                    />
                </Box>
                <Box pt={8}>
                    <LoadingDataGrid
                        apiUrl={'pins'}
                        columns={columns}
                        toolbarItems={toolbarItems}
                        dataGridFilter={
                            [
                                {name: 'status', value: '/api/pin_statuses/5'},
                                {name: 'project', value: state.project['@id']},
                                {name: 'threeSixtyRole', value: '/api/three_sixty_roles/1'},
                            ]
                        }
                        columnActions={columnActions}
                        reload={reload}
                        deleteAction={!Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')}
                    />
                </Box>
                <Notifications
                    cols={{
                        xs: 12,
                        lg: {
                            span: 6,
                            offset: 3
                        }
                    }}
                    error={notificationError}
                    successMessage={GlobalTransIntl('notification_send_success', intl)}
                    errorMessage={errorMessage}
                />
                <ModalElement/>
            </Box>
    );

    return (
        <Paper>
            {
                (
                    (!Object.keys(state.project).length) &&
                    <SelectProjectWrapper id='pins-create' rowClass={'p-5'}/>
                )
                ||
                content
            }
        </Paper>
    );
};

export default ThreeSixtyPins;