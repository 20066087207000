import React from 'react';
import Paper from "@material-ui/core/Paper";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import GlobalTrans from "../../helper/GlobalTrans";
import pages from "../../config/pages";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useStore} from "../../store/useStore";

function ModulesOverview() {
    const {dispatch} = useStore();

    const columns = [
        {field: 'name', headerName: GlobalTrans('name'), width: 350},
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.MODULES_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={() => onEdit(props)}
                        aria-label={GlobalTrans('title_edit')}
                        title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'modules'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'name',
                        label: GlobalTrans('name')
                    }
                ]}
                columnActions={columnActions}
                dataGridFilter={
                    [
                        {name: 'isNewestVersion', value: true}
                    ]
                }
                duplicateAction={true}
            />
        </Paper>
    );
}

export default ModulesOverview;