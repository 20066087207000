import React, {useCallback, useEffect, useState} from "react";
import Form from "react-bootstrap/cjs/Form";
import Button from "react-bootstrap/cjs/Button";
import {FormattedMessage} from "react-intl.macro";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent} from "@material-ui/core";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Validation from "../../helper/Validation";
import {hasRole, helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import Notifications from "../notifications/Notifications";
import Spinner from "../spinner/Spinner";
import Api from "../../helper/Api";
import roles from "../../config/roles";
import MaterialButton from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";

function UserCreate() {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);

    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [loadingData, setLoadingData] = useState(true);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingProjects, setLoadingProjects] = useState(true);
    const [countries, setCountries] = useState([]);
    const [projects, setProjects] = useState([]);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [firstName, setFirstName] = useState(state.editForm.firstName || '');
    const [lastName, setLastName] = useState(state.editForm.lastName || '');
    const [email, setEmail] = useState(state.editForm.email || '');
    const [validEmail, setValidEmail] = useState(true);
    const [userRoles, setUserRoles] = useState(state.editForm.roles || ['']);
    const [selectedCountrys, setSelectedCountrys] = useState((state.editForm.countries && state.editForm.countries.length) ? state.editForm.countries[0]['@id'] : []);
    const [selectedProject, setSelectedProject] = useState('');

    const formatProjects = (projects) => {
        const result = [];

        projects.forEach(project => {
            result.push(project['@id']);
        })

        return result;
    }
    const [selectedProjects, setSelectedProjects] = useState(state.editForm?.projects ? formatProjects(state.editForm.projects) : []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            Api.getApi(
                'countries',
                (res) => {
                    setCountries(res.data['hydra:member']);
                    setLoadingCountries(false);
                },
                catchErrors,
                state.token,
                cancelToken.token
            );

            Api.getApi(
                'projects',
                (res) => {
                    setProjects(res.data['hydra:member']);
                    setLoadingProjects(false);
                },
                catchErrors,
                state.token,
                cancelToken.token
            );

            setMounted(true);
        }
    }, [mounted, editForm, dispatch, state.token, cancelToken.token, catchErrors]);

    useEffect(() => {
        if (!loadingCountries && !loadingProjects) {
            setLoadingData(false);
        }
    }, [loadingCountries, loadingProjects, countries, projects, intl]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const sendSubmit = (e) => {
        e.preventDefault();

        let data = {
            firstName,
            lastName,
            email,
            roles: userRoles,
        };

        if (selectedCountrys.length && hasRole(userRoles, roles[1].name)) {
            data.countries = selectedCountrys;
        } else {
            data.countries = [];
        }

        if (selectedProjects.length && hasRole(userRoles, roles[2].name)) {
            data.projects = selectedProjects;
        } else {
            data.projects = [];
        }

        resetNotifications();

        if (validateEmail()) {
            if (editForm) {
                axios.put(config.apiUrl + `/users/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                    .then(res => {
                        if (res.data) {
                            showSuccess();
                        } else {
                            showError();
                        }
                    })
                    .catch(catchErrors);
            } else {
                axios.post(config.apiUrl + `/users`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                    .then((res) => {
                        if (res.data) {
                            setFirstName('');
                            setLastName('');
                            setEmail('');
                            setUserRoles(['']);
                            setSelectedProjects([]);
                            setSelectedCountrys([]);

                            showSuccess();

                            setTimeoutWatcher(setTimeout(() => {
                                resetNotifications();
                            }, 3000));
                        } else {
                            showError();
                        }
                    })
                    .catch(catchErrors);
            }
        }
    };

    const validateEmail = () => {
        let isEmailValid = Validation.isEmail(email);

        setValidEmail(isEmailValid);
        return isEmailValid;
    }

    const addSelectedProject = useCallback(() => {
        if (!selectedProject) {
            return;
        }

        const newSelectedProjects = [...selectedProjects];
        newSelectedProjects.push(selectedProject);

        setSelectedProject('');
        setSelectedProjects(newSelectedProjects);
    }, [selectedProject, selectedProjects]);

    const removeProject = useCallback((project) => {
        const projectIndex = selectedProjects.indexOf(project);

        const newSelectedProjects = [...selectedProjects];
        newSelectedProjects.splice(projectIndex, 1);

        setSelectedProjects(newSelectedProjects);
    }, [selectedProjects]);

    const SelectProjects = useCallback(() => {
        if (userRoles[0] !== roles[2].name) {
            return null;
        }

        return (
            <>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="formBasicProjects">
                            <FormControl variant={'outlined'} id={`user-create--project-select`}>
                                <InputLabel>
                                    {GlobalTransIntl('project', intl)}
                                </InputLabel>
                                <Select
                                    label={GlobalTransIntl('project', intl)}
                                    IconComponent={selectIconComponent}
                                    value={selectedProject}
                                    onChange={(e) => setSelectedProject(e.target.value)}
                                >
                                    {
                                        projects.map((item) => {
                                            if (selectedProjects.indexOf(item['@id']) === -1) {
                                                return <MenuItem value={item['@id']} key={item.id}
                                                                 data-name={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            }

                                            return null;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <MaterialButton
                            id={'user-create-project-add'}
                            variant="contained"
                            color="default"
                            className={''}
                            startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                            onClick={addSelectedProject}
                            aria-label={GlobalTransIntl('add', intl)}
                            title={GlobalTransIntl('add', intl)}
                        >
                            {GlobalTransIntl('add', intl)}
                        </MaterialButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Box mb={2}>
                            {
                                selectedProjects.map(project => (
                                    <Box key={project} py={1}>
                                        {projects.find(item => item['@id'] === project).name}
                                        <IconButton aria-label={GlobalTransIntl('remove', intl)}
                                                    title={GlobalTransIntl('remove', intl)}
                                                    size="small"
                                                    onClick={() => removeProject(project)}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                        </IconButton></Box>
                                ))
                            }
                        </Box>
                    </Col>
                </Row>
            </>
        )
    }, [addSelectedProject, intl, projects, selectedProject, selectedProjects, removeProject, userRoles]);

    const getCardContent = () => {
        if (loadingData || !roles.length) {
            return null;
        }

        return (
            <CardContent>
                <Form className={'user-create--form'} onSubmit={sendSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicName">
                                <TextField label={GlobalTransIntl('first_name', intl)}
                                           id={'user-create--first-name'}
                                           variant="outlined"
                                           onChange={(e) => {
                                               setFirstName(e.target.value)
                                           }}
                                           value={firstName}
                                           required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicLastName">
                                <TextField label={GlobalTransIntl('lst_name', intl)}
                                           id={'user-create--last-name'}
                                           variant="outlined"
                                           onChange={(e) => {
                                               setLastName(e.target.value)
                                           }}
                                           value={lastName}
                                           required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicEmail">
                                <TextField error={!validEmail}
                                           label={GlobalTransIntl('email', intl)}
                                           id={'user-create--email'}
                                           variant="outlined"
                                           onChange={(e) => {
                                               setEmail(e.target.value)
                                           }}
                                           helperText={GlobalTransIntl('error_field_email', intl)}
                                           value={email}
                                           required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicRole">
                                <FormControl
                                    variant={'outlined'}
                                    className={'user-create--form-control'}
                                    id={`user-create--role`}
                                    required={!editForm}
                                >
                                    <InputLabel>
                                        {GlobalTransIntl('role', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId="user-create--role-select"
                                        value={userRoles[0]}
                                        onChange={(e) => {
                                            setUserRoles([e.target.value]);
                                        }}
                                        label={GlobalTransIntl('role', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            roles.map((item, index) => {
                                                    if ((item.name === roles[1].name && !countries.length) || (item.id === roles[2].name && !projects.length) || item.create === false) {
                                                        return null;
                                                    }

                                                    return (
                                                        <MenuItem
                                                            key={index}
                                                            value={item.name}
                                                            data-name={item.name}
                                                        >
                                                            {GlobalTransIntl(item.label, intl)}
                                                        </MenuItem>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Form.Group>
                        </Col>
                    </Row>
                    <SelectProjects/>
                    <Button variant="primary" type="submit" className='user-create--submit'>
                        {
                            (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                        }
                    </Button>
                </Form>
            </CardContent>
        );
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='user.edit.headline' defaultMessage='Edit user'/>
        }

        return <FormattedMessage id='user.create.headline' defaultMessage='Create user'/>
    }

    return (
        <React.Fragment>
            <h1>
                <Headline/>
            </h1>
            <Notifications
                success={notificationSuccess}
                error={notificationError}
                errorMessage={errorMessage}
            />
            <Row>
                <Col xs={12} md={6}>
                    <Paper>
                        <Spinner show={loadingData} rowClass={'p-5'}/>
                        {
                            getCardContent()
                        }
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default UserCreate;