import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {getTranslationFromTranslations, helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import Api from "../../helper/Api";
import {FormattedMessage} from "react-intl.macro";
import Spinner from "../spinner/Spinner";
import {Box, CardContent, Grid, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TranslationsInput from "../translations/TranslationsInput";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import FormControl from "@material-ui/core/FormControl";
import Button from "react-bootstrap/cjs/Button";
import config from "../../config/config";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";

const InvitationsCreate = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [subject, setSubject] = useState(editForm?.subject || '');
    const [content, setContent] = useState(editForm?.content || '');

    const [selectedProject, setSelectedProject] = useState(editForm?.project['@id'] || '');
    const [selectedProcedure, setSelectedProcedure] = useState(editForm?.procedureObject['@id'] || '');

    const [loadingProjects, setLoadingProjects] = useState(true);
    const [loadingProcedures, setLoadingProcedures] = useState(true);
    const [projects, setProjects] = useState([]);
    const [procedures, setProcedures] = useState([]);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    };

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getProjects = useCallback(() => {
        Api.getApi(
            'projects',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProjects(res.data['hydra:member']);
                }

                setLoadingProjects(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getProcedures = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ]);

        Api.getApi(
            'procedures' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProcedures(res.data['hydra:member']);
                }

                setLoadingProcedures(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getProjects();
            getProcedures();
            setMounted(true);
        }
    }, [state.editForm, dispatch, editForm, mounted, getProjects, getProcedures]);

    const sendSubmit = (event) => {
        event.preventDefault();
        resetNotifications();

        const data = {
            project: selectedProject,
            procedureObject: selectedProcedure,
            subject,
            content,
            translations
        }

        if (editForm) {
            axios.put(config.apiUrl + `/invitations/${editForm.id}`, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'Content-Type': 'application/ld+json'}
            }))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/invitations`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data) {
                        setSelectedProject('');
                        setSelectedProcedure('');
                        setSubject('');
                        setContent('');
                        setTranslations([]);

                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='invitations.edit.headline' defaultMessage='Edit invitation'/>;
        }

        return <FormattedMessage id='invitations.create.headline' defaultMessage='Create invitation'/>;
    };

    return (
        <Paper>
            <Spinner show={loadingProjects || loadingProcedures} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                !loadingProjects && !loadingProcedures &&
                <CardContent>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h1>
                                    <Headline/>
                                </h1>
                            </Grid>
                            <Grid item>
                                <TranslationLanguages selectedLanguage={translationLanguage}
                                                      setLanguage={setTranslationLanguage}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <form className={`invitations--create-form`} onSubmit={sendSubmit}>
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl variant={'outlined'} id={'invitations-create-project'} required>
                                        <InputLabel id="invitations-create--select-project">
                                            {GlobalTransIntl('project', intl)}
                                        </InputLabel>
                                        <Select
                                            labelId="invitations-create--select-project"
                                            id="invitations-create-select-project"
                                            value={selectedProject}
                                            label={GlobalTransIntl('project', intl)}
                                            onChange={(event) => setSelectedProject(event.target.value)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                projects.map((project, index) => (
                                                    <MenuItem key={index} value={project['@id']}
                                                              data-name={project.name}>
                                                        {getTranslationFromTranslations(project.translations, state.localeIRI, 'name', project.name)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl variant={'outlined'} id={'invitations-create-procedure'} required>
                                        <InputLabel id="invitations-create--select-procedure">
                                            {GlobalTransIntl('assessment', intl)}
                                        </InputLabel>
                                        <Select
                                            labelId="invitations-create--select-procedure"
                                            id="invitations-create-select-procedure"
                                            value={selectedProcedure}
                                            label={GlobalTransIntl('project', intl)}
                                            onChange={(event) => setSelectedProcedure(event.target.value)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                procedures.map((procedure, index) => (
                                                    <MenuItem key={index} value={procedure['@id']}
                                                              data-name={procedure.name}>
                                                        {getTranslationFromTranslations(procedure.translations, state.localeIRI, 'name', procedure.name)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TranslationsInput
                                        field={'subject'}
                                        defaultValue={subject}
                                        setDefaultValue={setSubject}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('subject', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            required: true,
                                            id: `invitations-create--subject`
                                        }}
                                    />
                                </Grid>
                                <Box component={Grid} item md={6} display={{xs: 'none', md: 'block'}}/>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>{GlobalTransIntl('placeholder', intl)}</Grid>
                                        <Grid item xs={12}>%name% - {GlobalTransIntl('full_name', intl)}</Grid>
                                        <Grid item xs={12}>%link% - {GlobalTransIntl('pin_link', intl)}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TranslationsInput
                                        field={'content'}
                                        defaultValue={content}
                                        setDefaultValue={setContent}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('content', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            multiline: true,
                                            minRows: 12,
                                            required: true,
                                            id: `invitations-create--content`
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Button variant="primary" type="submit" className={`invitations--create-submit`}>
                            {
                                (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                            }
                        </Button>
                    </form>
                </CardContent>
            }
        </Paper>
    );
};

export default InvitationsCreate;