import React, {useCallback} from 'react';
import {Box, Grid, IconButton} from "@material-ui/core";
import {GlobalTransIntl} from "./GlobalTrans";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import TranslationsInput from "../components/translations/TranslationsInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {selectIconComponent} from "./Helper";

const ModuleElementOptions = (props) => {
    const intl = useIntl();

    const updateOption = useCallback((index, option) => {
        if (!option && props.answer === index) {
            props.setAnswer('');
        }

        const newOptions = [...props.options];

        newOptions.splice(index, 1);
        newOptions.splice(index, 0, option);

        props.setOptions(newOptions);
    }, [props]);

    const addOption = () => {
        const newOptions = [...props.options];

        newOptions.push('');
        props.setOptions(newOptions);
    };

    const removeOption = useCallback((index) => {
        if (props.answer !== null && props.answer !== '' && props.setAnswer) {
            if (props.answer === index) {
                props.setAnswer('');
            } else if (props.answer > index) {
                props.setAnswer(prev => prev - 1);
            }
        }

        const newOptions = [...props.options];
        newOptions.splice(index, 1);

        props.setOptions(newOptions);

        if (props.removeOption) {
            props.removeOption(index);
        }

        if (props.setTranslations) {
            props.setTranslations(prev => {
                const translations = [...prev];

                translations.forEach((translation) => {
                    translation['questions'].splice(index, 1);
                });

                return translations;
            });
        }
    }, [props]);

    const getSelectAnswerElement = () => {
        if (props.selectAnswer) {
            return props.selectAnswer;
        } else if (props.answer !== null && props.setAnswer) {
            return (
                <Box mb={2} display={'inline-block'} width={'100%'}>
                    <FormControl variant={'outlined'} required>
                        <InputLabel id="module-answer-select-label">
                            {GlobalTransIntl('correct_answer', intl)}
                        </InputLabel>
                        <Select
                            labelId="module-answer-select-label"
                            value={props.answer}
                            label={GlobalTransIntl('correct_answer', intl)}
                            onChange={(event) => props.setAnswer(event.target.value)}
                            IconComponent={selectIconComponent}
                        >
                            {
                                props.options.map((option, index) => (
                                    option && <MenuItem key={index} value={index}>{option}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            );
        }

        return null;
    }

    return (
        <React.Fragment>
            {
                props.options.map((option, index) =>
                    <Box mb={2} key={index}>
                        <Grid container>
                            <Grid item xs>
                                <TranslationsInput
                                    field={props.translationsField}
                                    fieldType={'array'}
                                    fieldIndex={index}
                                    defaultValue={option}
                                    setDefaultValue={(value) => {
                                        updateOption(index, value)
                                    }}
                                    translations={props.translations}
                                    language={props.translationLanguage}
                                    label={GlobalTransIntl(props.label, intl) + ' ' + (index + 1)}
                                    setTranslations={props.setTranslations}
                                    attributes={{
                                        required: true,
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            {
                                props.options.length > props.min &&
                                <Grid item xs={1} className={'align-self--center text-align-center'}>
                                    <IconButton onClick={() => removeOption(index)}
                                                title={GlobalTransIntl('delete', intl)}>
                                        <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                )
            }
            <Box mb={2}>
                <IconButton onClick={addOption}>
                    <FontAwesomeIcon icon={faPlus} size={"xs"}/>
                </IconButton>
            </Box>
            {getSelectAnswerElement()}
        </React.Fragment>
    );
};

ModuleElementOptions.propTypes = {
    options: PropTypes.array.isRequired,
    setOptions: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    translations: PropTypes.array.isRequired,
    setTranslations: PropTypes.func.isRequired,
    translationLanguage: PropTypes.string.isRequired,
    translationsField: PropTypes.string.isRequired,

    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    setAnswer: PropTypes.func,
    selectAnswer: PropTypes.element,
    removeOption: PropTypes.func,
    min: PropTypes.number,
}

ModuleElementOptions.defaultProps = {
    answer: null,
    min: 1,
}

export default ModuleElementOptions;