import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl.macro";
import {useStore} from "../../store/useStore";
import axios from "axios";
import Notifications from "../notifications/Notifications";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";
import config from "../../config/config";
import Api from "../../helper/Api";
import {
    getTranslationFromTranslations,
    helperCatchErrors, scrollToTop,
    selectIconComponent
} from "../../helper/Helper";
import Spinner from "../spinner/Spinner";
import FormControl from "@material-ui/core/FormControl";
import MaterialButton from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TranslationsInput from "../translations/TranslationsInput";
import Interpretations from "../interpretations/Interpretations";

function DimensionsCreate() {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm, setEditForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [name, setName] = useState(state.editForm.name || '');
    const [displayName, setDisplayName] = useState(state.editForm.displayName || '');
    const [construct, setConstruct] = useState((state.editForm.construct) ? state.editForm.construct['@id'] : '');
    const [scaleDescription, setScaleDescription] = useState(state.editForm.scaleDescription || '');
    const [result, setResult] = useState(state.editForm.result || '');
    const [interpretations, setInterpretations] = useState(state.editForm.interpretations || []);
    const [isHidden, setIsHidden] = useState(!!state.editForm.isHidden || false);
    const [templates, setTemplates] = useState(state.editForm.dimensionTemplates || []);
    const [isTemplate, setIsTemplate] = useState(false);
    const [editTemplateId, setEditTemplateId] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');

    const [constructs, setConstructs] = useState([]);
    const [loadingConstructs, setLoadingConstructs] = useState(true);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getConstructs = useCallback(() => {
        Api.getApi(
            'constructs',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setConstructs(res.data['hydra:member']);
                }

                setLoadingConstructs(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getConstructs();

            setMounted(true);
        }
    }, [mounted, getConstructs, dispatch, editForm]);

    const resetForm = () => {
        setName('');
        setDisplayName('');
        setConstruct('');
        setScaleDescription('');
        setResult('');
        setInterpretations([]);
        setIsHidden(false);
        setTemplates([]);
        setIsTemplate(false);
        setEditTemplateId('');
        setTranslations([]);
    }

    const addTemplate = () => {
        resetForm();
        setIsTemplate(true);
    }

    const editTemplate = () => {
        if (!selectedTemplate) {
            showError('no_template_selected');
        } else {
            resetForm();
            const template = editForm.dimensionTemplates.find(template => template['@id'] === selectedTemplate);
            setName(template.name);
            setDisplayName(template.displayName);
            setScaleDescription(template.scaleDescription);
            setResult(template.result);
            setInterpretations(template.interpretations || []);
            setTranslations(template.translations || [])
            setEditTemplateId(selectedTemplate);
            setSelectedTemplate('');
            setIsTemplate(true);
        }
    }

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    };

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        let data = {
                name,
                displayName,
                scaleDescription,
                interpretations,
                result,
                translations,
            },
            url = 'dimensions',
            editUrl;

        if (isTemplate) {
            data.dimension = editForm['@id'];

            url = 'dimension_templates';

            if (editTemplateId) {
                editUrl = config.apiHost + editTemplateId;
            }
        } else {
            data = {
                ...data,
                isHidden
            }

            if (construct) {
                data.construct = construct;
            }

            if (!isTemplate && editForm) {
                editUrl = config.apiUrl + `/${url}/${editForm.id}`;
            }
        }

        if ((!isTemplate && editForm) || editTemplateId) {
            axios.put(editUrl, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'Content-Type': 'application/ld+json'}
            }))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                        setEditForm(null);
                        resetForm();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/${url}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        resetForm();
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data['violations']) {
                        showError(error.response.data['violations'][0].message);
                    } else if (error.response) {
                        showError();
                    }
                });
        }
    };

    const Headline = () => {
        if (isTemplate) {
            if (editTemplateId) {
                return <FormattedMessage id='dimensions.edit.template.headline' defaultMessage='Edit template'/>
            }

            return <FormattedMessage id='dimensions.add.template.headline' defaultMessage='Add template'/>
        }

        if (editForm) {
            return <FormattedMessage id='dimensions.edit.headline' defaultMessage='Edit dimension'/>
        }

        return <FormattedMessage id='dimensions.create.headline' defaultMessage='Create dimension'/>
    };

    return (
        <Paper>
            <Spinner show={loadingConstructs} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                !loadingConstructs &&
                <CardContent>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h1>
                                    <Headline/>
                                </h1>
                            </Grid>
                            <Grid item>
                                <TranslationLanguages selectedLanguage={translationLanguage}
                                                      setLanguage={setTranslationLanguage}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Form className={`dimensions--create-form`} onSubmit={sendSubmit}>
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                {
                                    (!isTemplate) &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={isHidden}
                                                                   onChange={() => setIsHidden(prev => !prev)}
                                                                   name={GlobalTransIntl('is_hidden', intl)}
                                                                   id={'dimensions-create--is-hidden'}/>}
                                                label={GlobalTransIntl('is_hidden', intl)}
                                            />
                                        </Grid>
                                        {
                                            (constructs.length > 0) &&
                                            <>
                                                <Grid item xs={12} lg={6}>
                                                    <FormControl variant={'outlined'}>
                                                        <InputLabel id="dimensions-create--select-label">
                                                            {GlobalTransIntl('construct', intl)}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="dimensions-create--select-label"
                                                            id="dimensions-create-select"
                                                            value={construct}
                                                            label={GlobalTransIntl('construct', intl)}
                                                            onChange={(event) => setConstruct(event.target.value)}
                                                            IconComponent={selectIconComponent}
                                                        >
                                                            {
                                                                constructs.map((construct, index) => (
                                                                    <MenuItem key={index} value={construct['@id']}>
                                                                        {getTranslationFromTranslations(construct.translations, state.localeIRI, 'name', construct.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Box component={Grid} item lg={6}
                                                     sx={{display: {xs: 'none', lg: 'block'}}}></Box>
                                            </>
                                        }
                                    </>
                                }
                                <Grid item xs={12} lg={6}>
                                    <TextField label={GlobalTransIntl('name', intl)}
                                               id={'dimensions-create--name'}
                                               autoComplete={'off'}
                                               variant="outlined"
                                               onChange={(e) => setName(e.target.value)}
                                               value={name}
                                               required
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TranslationsInput
                                        field={'name'}
                                        defaultValue={displayName}
                                        setDefaultValue={setDisplayName}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('display_name', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            id: `dimensions-create--display-name`
                                        }}
                                    />
                                </Grid>
                                {
                                    (!isTemplate) &&
                                    <>
                                        {
                                            (editForm) &&
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item className={'align-self--center'}>
                                                        <MaterialButton
                                                            variant="contained"
                                                            color="default"
                                                            className={''}
                                                            startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                                                            onClick={addTemplate}
                                                            aria-label={GlobalTransIntl('add_template', intl)}
                                                            title={GlobalTransIntl('add_template', intl)}
                                                        >
                                                            {GlobalTransIntl('add_template', intl)}
                                                        </MaterialButton>
                                                    </Grid>
                                                    {
                                                        (templates.length > 0) &&
                                                        <>
                                                            <Grid item>
                                                                <Box minWidth={150}>
                                                                    <FormControl variant={'outlined'}>
                                                                        <InputLabel
                                                                            id="dimensions-create--select-template">
                                                                            {GlobalTransIntl('templates', intl)}
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId="dimensions-create--select-template"
                                                                            id="dimensions-create-select-template"
                                                                            value={selectedTemplate}
                                                                            label={GlobalTransIntl('templates', intl)}
                                                                            onChange={(event) => setSelectedTemplate(event.target.value)}
                                                                            IconComponent={selectIconComponent}
                                                                        >
                                                                            {
                                                                                templates.map((template) => (
                                                                                    <MenuItem key={template['@id']}
                                                                                              value={template['@id']}>
                                                                                        {template.name}
                                                                                    </MenuItem>
                                                                                ))
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item className={'align-self--center'}>
                                                                <MaterialButton
                                                                    variant="contained"
                                                                    color="default"
                                                                    className={''}
                                                                    startIcon={<FontAwesomeIcon icon={faPenToSquare}/>}
                                                                    onClick={editTemplate}
                                                                    aria-label={GlobalTransIntl('edit_template', intl)}
                                                                    title={GlobalTransIntl('edit_template', intl)}
                                                                >
                                                                    {GlobalTransIntl('edit_template', intl)}
                                                                </MaterialButton>
                                                            </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {GlobalTransIntl('dimensions.placeholder_description', intl)}:
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('user_name', intl)}:</Grid>
                                            <Grid item>%user_name%</Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('correct_answers', intl)}:</Grid>
                                            <Grid item>%correct_answers%</Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('solved_answers', intl)}:</Grid>
                                            <Grid item>%solved_tasks%</Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('max_answers', intl)}:</Grid>
                                            <Grid item>%max_tasks%</Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('percent_rang', intl)}:</Grid>
                                            <Grid item>%percent_rang%</Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('percent_remainder_rang', intl)}:</Grid>
                                            <Grid item>%percent_remainder_rang%</Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item>{GlobalTransIntl('gender_salutation', intl)}:</Grid>
                                            <Grid item>%salutation[male,female,various]%</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TranslationsInput
                                        setDefaultValue={setScaleDescription}
                                        field={'scaleDescription'}
                                        defaultValue={scaleDescription}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('scale_description', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            multiline: true,
                                            minRows: 4,
                                            required: true,
                                            id: `dimensions-create--scale-description`
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TranslationsInput
                                        setDefaultValue={setResult}
                                        field={'result'}
                                        defaultValue={result}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('result', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            multiline: true,
                                            minRows: 4,
                                            required: true,
                                            id: `dimensions-create--result`
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Interpretations
                                        interpretations={interpretations}
                                        setInterpretations={setInterpretations}
                                        translationLanguage={translationLanguage}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Button variant="primary" type="submit" className={`dimensions--create-submit`}>
                            {
                                (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                            }
                        </Button>
                    </Form>
                </CardContent>
            }
        </Paper>
    );
}

export default DimensionsCreate;