import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {helperCatchErrors, inputOnlyFloat, inputOnlyInteger, scrollToTop} from "../../helper/Helper";
import {FormattedMessage} from "react-intl.macro";
import {Box, CardContent, Grid, Paper} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import TextField from "@material-ui/core/TextField";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import config from "../../config/config";
import Button from "react-bootstrap/cjs/Button";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TranslationsInput from "../translations/TranslationsInput";

const ScalesForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);

    const [name, setName] = useState(state.editForm.name || '');
    const [size, setSize] = useState(state.editForm.size || '');
    const [average, setAverage] = useState(state.editForm.average || '');
    const [standardDeviation, setStandardDeviation] = useState(state.editForm.standardDeviation || '');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop()
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const showSuccess = () => {
        scrollToTop()
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            setMounted(true);
        }
    }, [mounted, dispatch, editForm]);

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        const data = {
            name,
            size: parseInt(size),
            average: parseFloat(average),
            standardDeviation: parseFloat(standardDeviation),
            translations,
        };

        if (editForm) {
            axios.put(config.apiUrl + `/scales/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/scales`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        setName('');
                        setAverage('');
                        setStandardDeviation('');
                        setNotificationSuccess(true);
                    } else {
                        setNotificationError(true);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data['violations']) {
                        setNotificationErrorMessage(error.response.data['violations'][0].message);
                        setNotificationError(true);
                    } else if (error.response) {
                        setNotificationErrorMessage('');
                        setNotificationError(true);
                    }
                });
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='scales.edit.headline' defaultMessage='Edit scale'/>
        }

        return <FormattedMessage id='scales.create.headline' defaultMessage='Create scale'/>
    }

    return (
        <Paper>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            <CardContent>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <h1>
                                <Headline/>
                            </h1>
                        </Grid>
                        <Grid item>
                            <TranslationLanguages selectedLanguage={translationLanguage}
                                                  setLanguage={setTranslationLanguage}/>
                        </Grid>
                    </Grid>
                </Box>
                <form className={`scales--create-form`} onSubmit={sendSubmit}>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <TranslationsInput
                                    field={'name'}
                                    defaultValue={name}
                                    setDefaultValue={setName}
                                    translations={translations}
                                    language={translationLanguage}
                                    label={GlobalTransIntl('name', intl)}
                                    setTranslations={setTranslations}
                                    attributes={{
                                        required: true,
                                        id: `scale-create--name`
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField label={GlobalTransIntl('size', intl)}
                                           id={`scale-create--size`}
                                           autoComplete={'off'}
                                           variant="outlined"
                                           onChange={(e) => {
                                               inputOnlyInteger(e, setSize)
                                           }}
                                           value={size}
                                           required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <TextField label={GlobalTransIntl('average', intl)}
                                                   id={`scale-create--average`}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => inputOnlyFloat(e, () => setAverage(e.target.value))}
                                                   value={average}
                                                   required
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TextField label={GlobalTransIntl('standard_deviation', intl)}
                                                   id={`scale-create--standard-deviation`}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => inputOnlyFloat(e, () => setStandardDeviation(e.target.value))}
                                                   value={standardDeviation}
                                                   required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Button variant="primary" type="submit" className={`scales--create-submit`}>
                        {
                            (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                        }
                    </Button>
                </form>
            </CardContent>
        </Paper>
    );
};

export default ScalesForm;