import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import Spinner from "../spinner/Spinner";
import {Box, CardContent, Grid, InputLabel, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import axios from "axios";
import Api from "../../helper/Api";
import {
    checkMultipleAxios,
    helperCatchErrors,
    inputOnlyInteger,
    inputOnlyIntegerAndZero, scrollToTop,
    selectIconComponent
} from "../../helper/Helper";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import FormControl from "@material-ui/core/FormControl";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import config from "../../config/config";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";
import pages from "../../config/pages";

const ReportDimensionAveragesForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [procedure, setProcedure] = useState(editForm?.procedureObject.id || '');
    const [procedureIRI, setProcedureIRI] = useState(editForm?.procedureObject['@id'] || '');
    const [selectedDimension, setSelectedDimension] = useState(editForm?.dimension['@id'] || '');
    const [selectedNormgroup, setSelectedNormgroup] = useState(editForm?.normgroup['@id'] || '');
    const [start, setStart] = useState(editForm?.start || '');
    const [end, setEnd] = useState(editForm?.end || '');

    // Collections
    const [dimensions, setDimensions] = useState([]);
    const [normgroups, setNormgroups] = useState([]);
    const [procedures, setProcedures] = useState([]);
    const [procedureDimensions, setProcedureDimensions] = useState([]);

    // Loading
    const [loadingData, setLoadingData] = useState(true);
    const [loadingDimensions, setLoadingDimensions] = useState(true);
    const [loadingNormgroups, setLoadingNormgroups] = useState(true);
    const [loadingProcedures, setLoadingProcedures] = useState(true);
    const [loadingProcedureDimensions, setLoadingProcedureDimensions] = useState(true);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }, []);

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getDimensions = useCallback(() => {
        Api.getApi(
            'dimensions',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setDimensions(res.data['hydra:member']);
                }

                setLoadingDimensions(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setNormgroups(res.data['hydra:member']);
                }

                setLoadingNormgroups(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getProcedures = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ]);

        Api.getApi(
            'procedures' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProcedures(res.data['hydra:member']);
                }

                setLoadingProcedures(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getProcedureDimensions = useCallback((procedure) => {
        axios.get(config.apiUrl + `/procedure/dimensions/${procedure}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setProcedureDimensions(res.data);
                    setLoadingProcedureDimensions(false);
                }
            })
            .catch(catchErrors);
    }, [state.token, cancelToken.token, catchErrors]);

    useEffect(() => {
        let checkAllAxios = checkMultipleAxios(
            [
                loadingDimensions,
                loadingNormgroups,
                loadingProcedures,
            ],
            [
                {
                    name: 'dimension',
                    value: dimensions
                },
                {
                    name: 'normgroup',
                    value: normgroups
                },
                {
                    name: 'assessment',
                    value: procedures
                },
            ],
            intl
        )

        if (checkAllAxios.length) {
            showError(checkAllAxios);
            setLoadingData(false);
        } else if (checkAllAxios === true) {
            setLoadingData(false);
        }
    }, [loadingDimensions, loadingNormgroups, loadingProcedures, dimensions, normgroups, procedures, intl, showError]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();

            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [cancelToken, timeoutWatcher]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getDimensions();
            getNormgroups();
            getProcedures();

            setMounted(true);
        }
    }, [mounted, getDimensions, getNormgroups, getProcedures, dispatch, editForm]);

    useEffect(() => {
        if (procedure) {
            getProcedureDimensions(procedure);
        }
    }, [procedure, getProcedureDimensions]);

    useEffect(() => {
        if (procedures.length && procedure) {
            const findProcedureIRI = procedures.find(filter => filter.id === procedure);

            if (findProcedureIRI) {
                setProcedureIRI(findProcedureIRI['@id']);
            }
        }
    }, [procedures, procedure])

    const Headline = () => {
        if (editForm) {
            return GlobalTransIntl('report_dimension_averages.edit.headline', intl);
        }

        return GlobalTransIntl('report_dimension_averages.create.headline', intl);
    };

    const SelectProcedure = () => (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={6} lg={4}>
                <FormControl variant={'outlined'} required>
                    <InputLabel id="report-dimension-averages-procedures-select-label">
                        {GlobalTransIntl('assessments', intl)}
                    </InputLabel>
                    <Select
                        labelId="report-dimension-averages-procedures-select-label"
                        id="report-dimension-averages-procedures-select"
                        value={procedure}
                        label={GlobalTransIntl('assessments', intl)}
                        onChange={(event) => setProcedure(event.target.value)}
                        IconComponent={selectIconComponent}
                    >
                        {
                            procedures.map((procedure) => (
                                <MenuItem key={procedure.id} value={procedure.id}>{procedure.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );

    const SelectDimensions = () => {
        const filteredDimensions = dimensions.filter(dimension => procedureDimensions.hasOwnProperty(dimension.id));

        return (
            <FormControl variant={'outlined'} id={'report-dimension-averages-dimensions'} disabled={editForm} required>
                <InputLabel id={'report-dimension-averages-dimensions-select-label'}>
                    {GlobalTransIntl('dimension', intl)}
                </InputLabel>
                <Select
                    labelId="report-dimension-averages-dimensions-select-label"
                    value={selectedDimension}
                    onChange={(event) => {
                        setSelectedDimension(event.target.value);
                    }}
                    label={GlobalTransIntl('dimension', intl)}
                    IconComponent={selectIconComponent}
                >
                    {
                        filteredDimensions.map((dimension) => (
                            <MenuItem
                                value={dimension['@id']}
                                key={dimension['@id']}
                            >
                                {dimension.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    };

    const SelectNormgroup = () => {
        return (
            <FormControl variant={'outlined'} id={'report-dimension-averages-normgroups'} disabled={editForm} required>
                <InputLabel id={'report-dimension-averages-normgroups-select-label'}>
                    {GlobalTransIntl('normgroups', intl)}
                </InputLabel>
                <Select
                    labelId="report-dimension-averages-normgroups-select-label"
                    value={selectedNormgroup}
                    onChange={(event) => {
                        setSelectedNormgroup(event.target.value);
                    }}
                    label={GlobalTransIntl('normgroups', intl)}
                    IconComponent={selectIconComponent}
                >
                    {
                        normgroups.map((normgroup) => (
                            <MenuItem
                                value={normgroup['@id']}
                                key={normgroup['@id']}
                            >
                                {normgroup.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    }

    const onSubmit = () => {
        const data = {
            dimension: selectedDimension,
            normgroup: selectedNormgroup,
            procedureObject: procedureIRI,
            start: parseInt(start),
            end: parseInt(end)
        }

        if (data.start > data.end) {
            showError(GlobalTransIntl('error_start_bigger_than_end', intl));
            return;
        }

        if (editForm) {
            axios.put(config.apiUrl + `/report_dimension_averages/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            dispatch({type: "setPage", payload: pages.REPORT_DIMENSION_AVERAGES_OVERVIEW});
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/report_dimension_averages`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data) {
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            dispatch({type: "setPage", payload: pages.REPORT_DIMENSION_AVERAGES_OVERVIEW});
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }

    const valuesForm = (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4} className={'align-self--center'}>
                <SelectDimensions/>
            </Grid>
            <Grid item xs={12} md={4} className={'align-self--center'}>
                <SelectNormgroup/>
            </Grid>
            <Grid item xs={12} md={2}>
                <TextField label={GlobalTransIntl('start', intl)}
                           id={'report-dimension-averages--start'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyIntegerAndZero(e, (value) => setStart(value), 100)
                           }}
                           value={start}
                           InputProps={{
                               endAdornment: '%',
                           }}
                           required
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <TextField label={GlobalTransIntl('end', intl)}
                           id={'report-dimension-averages--end'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, (value) => setEnd(value), 100)
                           }}
                           value={end}
                           InputProps={{
                               endAdornment: '%',
                           }}
                           required
                />
            </Grid>
        </Grid>
    );

    const getContent = () => {
        if (!procedure) {
            return <SelectProcedure/>;
        }

        const selectedProcedureName = procedures.find(filterProcedure => filterProcedure.id === procedure).name || '-';

        return (
            <Box mb={2}>
                {
                    (
                        loadingProcedureDimensions &&
                        <Spinner show={loadingData} rowClass={'p-5'}/>
                    )
                    ||
                    (
                        <Box mt={2}>
                            <Box mb={2}>
                                <Typography component={"h2"}>{selectedProcedureName}</Typography>
                            </Box>
                            {valuesForm}
                            <Box mt={2}>
                                <Button variant="primary" type="submit" className='project-create--submit'
                                        onClick={onSubmit}>
                                    {
                                        (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                                    }
                                </Button>
                            </Box>
                        </Box>
                    )
                }
            </Box>
        );
    }

    return (
        <Paper>
            <Spinner show={loadingData} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                !loadingData &&
                <CardContent>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h1>
                                    <Headline/>
                                </h1>
                            </Grid>
                        </Grid>
                    </Box>
                    {getContent()}
                </CardContent>
            }
        </Paper>
    );
};

export default ReportDimensionAveragesForm;