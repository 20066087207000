import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl.macro";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Paper from "@material-ui/core/Paper";
import {CardContent} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
    helperCatchErrors, scrollToTop,
    selectIconComponent
} from "../../helper/Helper";
import {useStore} from "../../store/useStore";
import axios from "axios";
import config from "../../config/config";
import Spinner from "../spinner/Spinner";
import Notifications from "../notifications/Notifications";

function Translation() {
    const {state, dispatch} = useStore();
    const intl = useIntl();
    const languageRef = useRef();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [editForm, setEditForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [language, setLanguage] = useState('');
    const [translation, setTranslation] = useState('');
    const [languages, setLanguages] = useState([]);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const getLanguageObjectFromEditForm = useCallback((languageId) => {
        return editForm.i18ns.find(item => item.language === languageId);
    }, [editForm.i18ns]);

    const getLanguageValueFromEditForm = useCallback((languageId) => {
        let languageObj = getLanguageObjectFromEditForm(languageId);

        return (languageObj) ? languageObj.value : '';
    }, [getLanguageObjectFromEditForm]);

    const getLanguageIdFromEditForm = useCallback((languageId) => {
        let languageObj = getLanguageObjectFromEditForm(languageId);

        return (languageObj) ? languageObj.id : '';
    }, [getLanguageObjectFromEditForm]);

    const updateI18nsInEditForm = (updateObj) => {
        let newEditFormI18ns = editForm.i18ns.map(el => (
                el.id === updateObj.id ? updateObj : el
            )),
            newEditForm = {
                ...editForm,
                i18ns: newEditFormI18ns
            };

        setEditForm(newEditForm);
    }

    const addI18nsInEditForm = (addObj) => {
        let newEditForm = {
            ...editForm,
            i18ns: [
                ...editForm.i18ns,
                addObj
            ]
        };

        setEditForm(newEditForm);
    }

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            axios.get(config.apiUrl + `/languages`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        setLanguages(res.data['hydra:member']);
                        setLoadingData(false);

                        let defaultLanguageId = res.data['hydra:member'][0]['@id'];
                        setLanguage(defaultLanguageId);
                        setTranslation(getLanguageValueFromEditForm(defaultLanguageId));
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {

                    } else if (error.response) {
                        console.log(error.response);
                    }
                });

            setMounted(true);
        }
    }, [mounted, cancelToken.token, dispatch, getLanguageValueFromEditForm, state.token, editForm]);

    // Cleanup Effect
    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);

        // set translation content
        let translationValue = getLanguageValueFromEditForm(event.target.value);
        setTranslation(translationValue);
    };

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const showSuccess = () => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        let data = {
                value: translation,
                language,
                translationKey: editForm['@id']
            },
            languageId = getLanguageIdFromEditForm(language);

        if (languageId) {
            axios.put(config.apiUrl + `/i18ns/${languageId}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        updateI18nsInEditForm(res.data);
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            resetNotifications();
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/i18ns`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data) {
                        addI18nsInEditForm(res.data);
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            resetNotifications();
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }

    const getCardContent = () => {
        if (loadingData) {
            return null;
        }

        return (
            <CardContent>
                <Form className={'translation--form'} onSubmit={onSubmit}>
                    <Row>
                        <Col xs={12}>
                            <Form.Group controlId="formBasicLanguage">
                                <FormControl variant={'outlined'} className={'translation--form-control'}
                                             id={`translation--language`} required>
                                    <InputLabel>
                                        {GlobalTransIntl('language', intl)}
                                    </InputLabel>
                                    <Select
                                        ref={languageRef}
                                        id="translation--language-select"
                                        value={language}
                                        onChange={handleChangeLanguage}
                                        label={GlobalTransIntl('language', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            languages.map((item, key) =>
                                                <MenuItem value={item['@id']} key={key}
                                                          data-name={item.name}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Form.Group>
                        </Col>
                        <Col cs={12}>
                            <Form.Group controlId="formBasicTranslation">
                                <TextField
                                    label={GlobalTransIntl('translations', intl)}
                                    id={'translation--translation'}
                                    variant="outlined"
                                    onChange={(event) => {
                                        setTranslation(event.target.value)
                                    }}
                                    value={translation}
                                    multiline
                                    minRows={4}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className='translation--submit'>
                                {
                                    GlobalTransIntl('button_save', intl)
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </CardContent>
        );
    }

    return (
        <React.Fragment>
            <h1>
                <FormattedMessage
                    id='translation.headline'
                    defaultMessage='Translations for "{key}"'
                    values={{key: (editForm.value)}}
                />
            </h1>
            <Notifications
                success={notificationSuccess}
                error={notificationError}
                errorMessage={errorMessage}
            />
            <Row>
                <Col cs={12} xl={8}>
                    <Paper>
                        <Spinner show={loadingData} rowClass={'p-5'}/>
                        {
                            getCardContent()
                        }
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Translation;