import React from "react";
import {FormattedMessage} from "react-intl.macro";
import CreateWithName from "../createWithName/CreateWithName";

function CustomerCreate() {
    return (
        <CreateWithName apiUrl={'customers'}
                        headline={<FormattedMessage id='customer.create.headline' defaultMessage='Create customer'/>}
                        class={'customer'}/>
    );
}

export default CustomerCreate;