import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import Api from "../../helper/Api";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {helperCatchErrors, scrollToTop, selectIconComponent} from "../../helper/Helper";
import Spinner from "../spinner/Spinner";
import {Box, Grid, Select} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "react-bootstrap/cjs/Button";
import config from "../../config/config";

const AssessmentDimensionTemplateForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [mounted, setMounted] = useState(false);
    const [cancelToken] = useState(axios.CancelToken.source());
    const [procedureObject] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [dimensions, setDimensions] = React.useState([]);
    const [loadingDimensions, setLoadingDimensions] = React.useState(true);
    const [selectedDimension, setSelectedDimension] = React.useState('');
    const [selectedTemplates, setSelectedTemplates] = React.useState(procedureObject.dimensionTemplates || {});

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop()
        setNotificationError(true);
    }, []);

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const showSuccess = useCallback(() => {
        scrollToTop();
        setNotificationError(false);
        setNotificationSuccess(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const getDimensions = useCallback(() => {
        const filter = ApiFilterBuilder([
            {
                name: 'isHidden',
                value: false,
            }
        ]);

        Api.getApi(
            'dimensions' + filter,
            (res) => {
                if (res.data['hydra:member'].length) {
                    const filteredDimensions = res.data['hydra:member'].filter(dimension => Object.keys(procedureObject.dimensionTemplates).indexOf(dimension.id.toString()) !== -1);

                    setDimensions(filteredDimensions);
                    setSelectedDimension(filteredDimensions[0].id);
                } else {
                    showError(GlobalTransIntl('error_no_dimension', intl));
                }

                setLoadingDimensions(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, intl, showError, state.token, procedureObject.dimensionTemplates]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (!procedureObject) {
                showError(GlobalTransIntl('error_no_assessment_id', intl));
            } else {
                dispatch({type: "resetEditForm"});

                getDimensions();
            }
        }
    }, [getDimensions, dispatch, intl, mounted, procedureObject, showError]);

    const updateTemplates = (template) => {
        setSelectedTemplates(prev => {
            return {
                ...prev,
                [selectedDimension]: template
            };
        });
    }

    const getSelectedDimensionTemplates = () => {
        return dimensions.find(dimension => dimension.id === selectedDimension)?.dimensionTemplates;
    }

    const submit = () => {
        const data = {
            dimensionTemplates: selectedTemplates
        };

        resetNotifications();

        axios.patch(config.apiUrl + `/procedures/` + procedureObject.id, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/merge-patch+json'}}))
            .then(res => {
                if (res.status === 200) {
                    showSuccess();
                } else {
                    showError("Status " + res.status);
                }
            })
            .catch(catchErrors);
    }

    return (
        <Paper>
            <Box p={2}>
                <Spinner show={loadingDimensions} rowClass={'p-5'}/>
                <Box pt={2} pr={2} pl={2}>
                    <Notifications
                        success={notificationSuccess}
                        error={notificationError}
                        errorMessage={errorMessage}
                    />
                </Box>
                {
                    !loadingDimensions &&
                    <>
                        {
                            (dimensions.length > 0) &&
                            <Box mb={2}>
                                <Grid container justifyContent={'center'} spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <FormControl variant={'outlined'}
                                                     id={'assessments-dimension-templates-dimension'}>
                                            <InputLabel>
                                                {GlobalTransIntl('dimension', intl)}
                                            </InputLabel>
                                            <Select
                                                labelId="assessments--dimension-templates-dimension-select"
                                                value={selectedDimension}
                                                onChange={(event) => {
                                                    setSelectedDimension(event.target.value);
                                                }}
                                                label={GlobalTransIntl('dimension', intl)}
                                                IconComponent={selectIconComponent}
                                            >
                                                {
                                                    dimensions.map((dimension) => (
                                                        <MenuItem value={dimension.id} key={dimension.id}>
                                                            {dimension.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {
                                        (getSelectedDimensionTemplates().length > 0) &&
                                        <Grid item xs={12} lg={6}>
                                            <FormControl variant={'outlined'}
                                                         id={'assessments-dimension-templates-template'}>
                                                <InputLabel>
                                                    {GlobalTransIntl('templates', intl)}
                                                </InputLabel>
                                                <Select
                                                    labelId="assessments--dimension-templates-dimension-template"
                                                    value={selectedTemplates[selectedDimension]}
                                                    onChange={(event) => {
                                                        updateTemplates(event.target.value);
                                                    }}
                                                    label={GlobalTransIntl('dimension', intl)}
                                                    IconComponent={selectIconComponent}
                                                >
                                                    <MenuItem value={''}>-</MenuItem>
                                                    {
                                                        getSelectedDimensionTemplates().map((template) => (
                                                            <MenuItem value={template.id} key={template.id}>
                                                                {template.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        }
                        <Box mt={2}>
                            <Button variant="primary" type="submit" onClick={submit}>
                                {GlobalTransIntl('button_save', intl)}
                            </Button>
                        </Box>
                    </>
                }
            </Box>
        </Paper>
    )
        ;
};

export default AssessmentDimensionTemplateForm;