import React, {useEffect, useState} from "react";
import {useStore} from "../../store/useStore";
import {FormattedMessage} from "react-intl.macro";
import SelectProjectWrapper from "../selectProject/selectProjectWrapper";
import {Box, Divider, Grid} from "@material-ui/core";
import axios from "axios";
import config from "../../config/config";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Spinner from "../spinner/Spinner";
import {useIntl} from "react-intl";

function Dashboard() {
    const {state} = useStore();
    const intl = useIntl();
    const [cancelToken] = useState(axios.CancelToken.source());

    const [totalPins, setTotalPins] = useState();
    const [loadingTotalPins, setLoadingTotalPins] = useState(false);
    const [loadedProject, setLoadedProject] = useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken]);

    useEffect(() => {
        if (Object.keys(state.project).length && (!loadedProject || state.project !== loadedProject) && !loadingTotalPins) {
            setLoadingTotalPins(true);
            setLoadedProject(state.project);

            axios.get(config.apiUrl + `/dashboardData/${state.project.id}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    setTotalPins(res.data);
                    setLoadingTotalPins(false);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data); // => the response payload
                    }
                });
        }
    }, [state.project, totalPins, loadingTotalPins, cancelToken.token, state.token, loadedProject]);

    const renderPinStatus = (object, totalAmount, xsCol1, xsCol2) => {
        if (Object.keys(totalPins).length) {
            let result = [];

            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    result.push(
                        <Grid item container key={key}>
                            <Grid item xs={xsCol1}>
                                <Box borderRight={1} mr={2}>
                                    {GlobalTransIntl(key.toLowerCase(), intl)}
                                </Box>
                            </Grid>
                            <Grid item xs={xsCol2}>
                                <Box textAlign="center">
                                    {object[key]}
                                </Box>
                            </Grid>
                        </Grid>
                    );
                }
            }

            return (
                <Grid item container>
                    <Grid item xs={xsCol1}>
                        <Box mb={1} borderBottom={1} fontWeight={"bold"}>
                            {GlobalTransIntl('status', intl)}
                        </Box>
                    </Grid>
                    <Grid item xs={xsCol2}>
                        <Box mb={1} borderBottom={1} textAlign="center" fontWeight={"bold"}>
                            {GlobalTransIntl('amount', intl)}
                        </Box>
                    </Grid>

                    {result}

                    <Grid item container>
                        <Grid item xs={xsCol1}>
                            <Box fontWeight={'bold'} mt={2}>
                                {GlobalTransIntl('total_amount', intl)}
                            </Box>
                        </Grid>
                        <Grid item xs={xsCol2}>
                            <Box textAlign="center" fontWeight={"bold"} mt={2}>
                                {totalAmount}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Box fontWeight={'bold'} mt={2}>
                    <Spinner show={true}/>
                </Box>
            )
        }
    };


    const getContent = () => {
        if (Object.keys(state.project).length && !!totalPins) {
            return (
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box mr={2} px={3}>
                            <Box mb={2}>
                                <h4>{GlobalTransIntl('pins_in_project', intl)}:</h4>
                            </Box>
                            {renderPinStatus(totalPins.totalPinsStatus, totalPins.totalPins, 7, 5)}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box px={3}>
                            <Box mb={2}>
                                <h4>360&deg; {GlobalTransIntl('pins_in_project', intl)}:</h4>
                            </Box>
                            {renderPinStatus(totalPins.total360PinsStatus, totalPins.total360Pins, 7, 5)}
                        </Box>
                    </Grid>
                </Grid>
            );
        }

        return <SelectProjectWrapper id='dashboard'/>;
    }

    return (
        <React.Fragment>
            <h1>
                <FormattedMessage
                    id="dashboard.main_headline"
                    defaultMessage="Dashboard"
                />
            </h1>
            <Box mb={2} mt={2}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <h2>
                            <FormattedMessage
                                id="dashboard.headline"
                                defaultMessage="Welcome {username}"
                                values={{
                                    username: ''
                                }}
                            />
                        </h2>
                    </Grid>
                    {
                        (Object.keys(state.project).length > 0) &&
                        <Grid item xs={12} md={6}>
                            <Box textAlign={'right'}>
                                <h2>
                                    <FormattedMessage
                                        id="dashboard.title"
                                        defaultMessage="Dashboard Content for {project_name}"
                                        values={{
                                            project_name: state.project.name
                                        }}
                                    />
                                </h2>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>

            <Divider/>

            <Box mt={2}>
                <Spinner show={Object.keys(state.project).length && !!!totalPins}/>
                {getContent()}
            </Box>
        </React.Fragment>
    );
}

export default Dashboard;