import React from "react";
import {FormattedMessage} from "react-intl.macro";
import CreateWithName from "../createWithName/CreateWithName";

function NormgroupsCreate() {
    return (
        <CreateWithName
            apiUrl={'normgroups'}
            headline={<FormattedMessage id='normgroups.create.headline' defaultMessage='Create normgroup'/>}
            class={'normgroups'}
            hasTranslation={true}
        />
    );
}

export default NormgroupsCreate;