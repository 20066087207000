import {useStore} from "../../store/useStore";
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import axios from "axios";
import config from "../../config/config";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Grid} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";
import Notifications from "../notifications/Notifications";
import TranslationsInput from "../translations/TranslationsInput";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import {scrollToTop} from "../../helper/Helper";

function CreateWithName(props) {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [name, setName] = useState(state.editForm.name || '');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showSuccess = () => {
        setName('');
        setNotificationSuccess(true);
    }

    const catchErrors = error => {
        scrollToTop();

        if (error.response && error.response.data['violations']) {
            setNotificationErrorMessage(error.response.data['violations'][0].message);
            setNotificationError(true);
        } else if (error.response) {
            setNotificationErrorMessage('');
            setNotificationError(true);
        }
    }

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            setMounted(true);
        }
    }, [mounted, dispatch, editForm]);

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        const data = {
            name
        };

        if (props.hasTranslation) {
            data.translations = translations;
        }

        if (editForm) {
            axios.put(config.apiUrl + `/${props.apiUrl}/${editForm.id}`, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'Content-Type': 'application/ld+json'}
            }))
                .then(res => {
                    scrollToTop();

                    if (res.data) {
                        showSuccess();
                    } else {
                        setNotificationError(true);
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/${props.apiUrl}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        showSuccess();
                    } else {
                        setNotificationError(true);
                    }
                })
                .catch(catchErrors);
        }
    };

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const onChangeName = (e) => {
        setName(e.target.value)
    };

    return (
        <React.Fragment>
            <Notifications success={notificationSuccess}
                           error={notificationError}
                           errorMessage={GlobalTrans(notificationErrorMessage)}
            />
            <Row>
                <Col cs={12} lg={6}>
                    <Paper className={`${props.class}--create-paper`}>
                        <CardContent>
                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <h1>
                                            {props.headline}
                                        </h1>
                                    </Grid>
                                    {
                                        props.hasTranslation &&
                                        <Grid item>
                                            <TranslationLanguages selectedLanguage={translationLanguage}
                                                                  setLanguage={setTranslationLanguage}/>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <Form className={`${props.class}--create-form`} onSubmit={sendSubmit}>
                                {
                                    (
                                        props.hasTranslation &&
                                        <Box mb={2}>
                                            <TranslationsInput
                                                field={'name'}
                                                defaultValue={name}
                                                setDefaultValue={setName}
                                                translations={translations}
                                                language={translationLanguage}
                                                label={GlobalTransIntl('name', intl)}
                                                setTranslations={setTranslations}
                                                attributes={{
                                                    required: true,
                                                    id: `${props.class}-create--name`,
                                                }}
                                            />
                                        </Box>
                                    )
                                    ||
                                    <Form.Group controlId="formBasicName">
                                        <TextField label={GlobalTransIntl('name', intl)}
                                                   id={`${props.class}-create--name`}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={onChangeName}
                                                   value={name}
                                                   required
                                        />
                                    </Form.Group>
                                }

                                <Button variant="primary" type="submit" className={`${props.class}--create-submit`}>
                                    {
                                        (editForm) ? GlobalTrans('button_save') : GlobalTrans('button_create')
                                    }
                                </Button>
                            </Form>
                        </CardContent>
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
}

CreateWithName.propTypes = {
    hasTranslation: PropTypes.bool,
}

CreateWithName.defaultProps = {
    hasTranslation: false,
}

export default CreateWithName;