import React from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import {useStore} from "../../store/useStore";
import pages from "../../config/pages";
import roles from "../../config/roles";
import {useIntl} from "react-intl";

function UserOverview() {
    const intl = useIntl();
    const {dispatch} = useStore();

    const columns = [
        {field: 'firstName', headerName: GlobalTrans('first_name'), width: 200},
        {field: 'lastName', headerName: GlobalTrans('last_name'), width: 200},
        {field: 'email', headerName: GlobalTrans('email'), width: 200},
        {
            field: 'roles', headerName: GlobalTrans('role'), width: 180,
            valueGetter: (params) => {
                let row = params.row;

                const role = roles.find(role => role.name === row.roles[0]);

                return GlobalTransIntl(role.label, intl);
            }
        },
        {
            field: 'country', headerName: GlobalTrans('country'), width: 180,
            valueGetter: (params) => {
                let row = params.row;

                if (row.countries.length > 1) {
                    let countries = '';

                    row.countries.forEach((item, key) => {
                        if (key > 0) {
                            countries += ', ';
                        }
                        countries += item.name;
                    });

                    return countries;
                } else if (row.countries.length === 1) {
                    return row.countries[0].name;
                }
            }
        },
        {
            field: 'projects', headerName: GlobalTrans('project'), width: 250,
            valueGetter: (params) => {
                const row = params.row;

                if (row.projects.length > 1) {
                    let projects = '';

                    row.projects.forEach((item, key) => {
                        if (key > 0) {
                            projects += ', ';
                        }
                        projects += item.name;
                    });

                    return projects;
                } else if (row.projects.length === 1) {
                    return row.projects[0].name;
                }
            }
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            hide: true,
            valueGetter: (params) =>
                `${params.getValue(params.id, 'firstName') || ''} ${
                    params.getValue(params.id, 'lastName') || ''
                }`,
        }
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.USER_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={() => onEdit(props)}
                        aria-label={GlobalTrans('title_edit')}
                        title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'users'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'fullName',
                        label: GlobalTrans('name')
                    },
                    {
                        type: 'select',
                        getUrl: 'countries',
                        name: 'country',
                        label: GlobalTrans('country')
                    },
                    {
                        type: 'text',
                        name: 'projects',
                        label: GlobalTrans('project')
                    }
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
}

export default UserOverview;