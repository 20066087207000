import React from 'react';
import Col from "react-bootstrap/cjs/Col";
import SelectProject from "./SelectProject";
import Row from "react-bootstrap/cjs/Row";
import PropTypes from "prop-types";
import GlobalTrans from "../../helper/GlobalTrans";

function SelectProjectWrapper(props) {
    return (
        <Row className={'align-items-center' + ((props.rowClass) ? ' ' + props.rowClass : '')}>
            <Col xs={'auto'}>
                <span>
                    {GlobalTrans('select_project_wrapper')}
                </span>
            </Col>
            <Col xs={'auto'}>
                <SelectProject id={props.id}/>
            </Col>
        </Row>
    );
}

SelectProjectWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    rowClass: PropTypes.string
}

export default SelectProjectWrapper;