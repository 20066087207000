import React, {useCallback, useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import GlobalTrans from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import Notifications from "../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import {useStore} from "../../store/useStore";
import Validation from "../../helper/Validation";
import axios from "axios";
import config from "../../config/config";
import {getTokenId} from "../../helper/Helper";
import pages from "../../config/pages";
import {CardContent, Grid} from "@material-ui/core";

const ChangePassword = () => {
    const {state, dispatch} = useStore();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [validPasswordRepeat, setValidPasswordRepeat] = useState(true);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const isPasswordValid = useCallback(() => {
        let isPasswordValid = Validation.validatePassword(password);
        setValidPassword(isPasswordValid);

        return isPasswordValid;
    }, [password]);

    const isPasswordRepeatValid = useCallback(() => {
        let isPasswordRepeatValid = Validation.validatePasswordRepeat(password, passwordRepeat);
        setValidPasswordRepeat(isPasswordRepeatValid);

        return isPasswordRepeatValid;
    }, [password, passwordRepeat]);

    const resetNotification = () => {
        setNotificationError(false);
    }

    const showError = (message = '') => {
        setErrorMessage(message);
        setNotificationError(true);
    }

    const onSubmit = useCallback((event) => {
        let validatePassword = isPasswordValid(),
            validatePasswordRepeat = isPasswordRepeatValid();

        event.preventDefault();
        resetNotification();

        if (validatePassword && validatePasswordRepeat) {
            let data = {
                    password
                },
                tokenId = getTokenId(state.token);

            axios.patch(config.apiUrl + `/users/${tokenId}`, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'content-type': 'application/merge-patch+json'}
            }))
                .then(res => {
                    if (res.status === 200) {
                        dispatch({type: "setPage", payload: pages.DASHBOARD});
                    } else {
                        console.log(res);
                        showError();
                    }
                })
                .catch(() => {
                    showError();
                });
        }
    }, [dispatch, isPasswordValid, isPasswordRepeatValid, password, state.token, cancelToken.token]);

    return (
        <React.Fragment>
            <h1>
                {GlobalTrans('change_password')}
            </h1>
            <Notifications
                error={notificationError}
                errorMessage={errorMessage}
            />
            <Grid container>
                <Grid item lg={6} xs={12}>
                    <Paper>
                        <CardContent>
                            <form className={'change-password--form'} onSubmit={onSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField error={!validPassword}
                                                   label={GlobalTrans('password')}
                                                   id={'forgot-password-password'}
                                                   variant="outlined"
                                                   onChange={(e) => setPassword(e.target.value)}
                                                   helperText={GlobalTrans('error_password')}
                                                   type={'password'}
                                                   value={password}
                                                   required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField error={!validPasswordRepeat}
                                                   label={GlobalTrans('password_repeat')}
                                                   id={'forgot-password-password-repeat'}
                                                   variant="outlined"
                                                   onChange={(e) => setPasswordRepeat(e.target.value)}
                                                   helperText={GlobalTrans('error_password_repeat')}
                                                   type={'password'}
                                                   value={passwordRepeat}
                                                   required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="primary" type="submit" block
                                                className='change-password--submit'>
                                            {GlobalTrans('button_submit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ChangePassword;