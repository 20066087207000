import React from "react";

import {Grid} from "@material-ui/core";

function Footer() {
    return (
        <footer className='footer'>
            <Grid container justifyContent={"center"}>
                <Grid item>
                    Brooklynmaxx GmbH, Agrippinawerft 10, D - 50678 Köln, <a className="footerText" href="https://brooklynmaxx.com" target="_blank" rel="noreferrer">www.brooklynmaxx.com</a>
                </Grid>
            </Grid>
        </footer>
    )
}

export default Footer;