import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import pages from "../../config/pages";
import TextField from "@material-ui/core/TextField";
import {Divider} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Validation from "../../helper/Validation";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Token from "../../helper/Token";
import {LoadingButton} from "@mui/lab";

// Assets
import logo from "../../assets/img/logo_bmxx.png";
import logo_light from "../../assets/img/logo_bmxx_light.png";

// React Bootstrap
import Form from "react-bootstrap/cjs/Form";
import Button from "react-bootstrap/cjs/Button";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Container from "react-bootstrap/cjs/Container";
import {Alert} from "react-bootstrap";
import Image from "react-bootstrap/cjs/Image";

// i18n
import {FormattedMessage} from 'react-intl.macro';
import {useIntl} from "react-intl";

function Login() {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [errorNotification, setErrorNotification] = useState(false);
    const [errorCredentials, setErrorCredentials] = useState(false);
    const [errorSystem, setErrorSystem] = useState(false);
    const [showNotificationTokenExpired, setShowNotificationTokenExpired] = useState(false);

    const [loading, setLoading] = useState(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (!mounted) {
            if (state.tokenExpired) {
                dispatch({type: "setTokenExpired", payload: false});
                setShowNotificationTokenExpired(true);
            } else {
                setShowNotificationTokenExpired(false);
            }
            setMounted(true);
        }
    }, [state.tokenExpired, dispatch, mounted]);

    const sendSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        resetNotifications();
        setShowNotificationTokenExpired(false);

        if (validateForm()) {
            const data = {
                email,
                password
            };

            axios.post(config.apiUrl + `/login_check`, data, {
                withCredentials: true
            })
                .then(res => {
                    if (res.data.token && Token.hasRoles(Token.getTokenData(res.data.token), ['ROLE_SUPERADMIN', 'ROLE_NATIONALADMIN', 'ROLE_PROJECTADMIN'])) {
                        dispatch({
                            type: 'setTokens',
                            payload: {
                                token: res.data.token,
                                refreshToken: res.data.refresh_token
                            }
                        });
                        dispatch({
                            type: 'setPage',
                            payload: pages.DASHBOARD
                        });
                    } else {
                        setErrorNotification(true);
                        setErrorSystem(true);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        setErrorNotification(true);
                        setErrorCredentials(true);
                    }

                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    const resetNotifications = () => {
        setErrorNotification(false);
        setErrorSystem(false);
        setErrorCredentials(false);
    }

    const validateForm = () => {
        let validatedEmail = validateEmail(),
            validatedPassword = validatePassword();

        return (validatedEmail && validatedPassword);
    }

    const validateEmail = () => {
        let validationEmail = Validation.isEmail(email);

        setValidEmail(validationEmail);

        return validationEmail;
    }

    const validatePassword = () => {
        let validationPassword = password.length >= 8;
        setValidPassword(validationPassword);

        return validationPassword;
    }

    const onForgotPassword = () => {
        dispatch({
            type: 'setPage',
            payload: pages.FORGOT_PASSWORD
        });
    }

    return (
        <main className='main-content login'>
            <Container className='main-content--container container--absolute-center'>
                <Row>
                    <Col xs={12} lg={{span: 6, offset: 3}}>
                        <Paper className='main-box'>
                            <Row>
                                <Col xs={12} lg={{span: 10, offset: 1}}>
                                    {
                                        (
                                            state.theme === 'light' &&
                                            <Image className='logo' src={logo_light} alt={'Logo'} fluid/>
                                        ) || (
                                            <Image className='logo' src={logo} alt={'Logo'} fluid/>
                                        )
                                    }
                                </Col>
                            </Row>
                            {
                                errorNotification &&
                                <React.Fragment>
                                    <Alert variant='danger' show={errorCredentials}>
                                        <FormattedMessage
                                            id="login.notification.invalid_credentials"
                                            defaultMessage="Invalid credentials!"
                                        />
                                    </Alert>
                                    <Alert variant='danger' show={errorSystem}>
                                        <FormattedMessage
                                            id="login.notification.api_error"
                                            defaultMessage="System error!{br}Please try again later."
                                            values={{
                                                br: <br/>
                                            }}
                                        />
                                    </Alert>
                                </React.Fragment>
                            }
                            {
                                showNotificationTokenExpired &&
                                <Alert variant='danger'>
                                    {
                                        GlobalTransIntl('jwt_expired', intl)
                                    }
                                </Alert>
                            }
                            <Form className={'login--form'} onSubmit={sendSubmit}>
                                <Form.Group controlId="formBasicEmail">
                                    <TextField error={!validEmail}
                                               label={GlobalTrans('email')}
                                               id={'login-email'}
                                               variant="outlined"
                                               onChange={(e) => setEmail(e.target.value)}
                                               helperText={GlobalTrans('error_email')}
                                               required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <TextField error={!validPassword}
                                               label={GlobalTrans('password')}
                                               id={'login-password'}
                                               variant="outlined"
                                               onChange={(e) => setPassword(e.target.value)}
                                               helperText={GlobalTrans('error_password')}
                                               type={'password'}
                                               required
                                    />
                                </Form.Group>
                                <LoadingButton
                                    className={'login--submit'}
                                    loading={loading}
                                    variant={"contained"}
                                    type={"submit"}
                                    disabled={loading}
                                    fullWidth
                                >
                                    <FormattedMessage
                                        id="login.submit"
                                        defaultMessage="Login"
                                    />
                                </LoadingButton>
                            </Form>
                            <Divider light/>
                            <Button className={'login--forgot-password'} variant="link" onClick={onForgotPassword}>
                                <FormattedMessage
                                    id="login.forgot_password"
                                    defaultMessage="Forgot password?"
                                />
                            </Button>
                        </Paper>
                    </Col>
                </Row>
            </Container>
        </main>
    );
}

export default Login;